import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faGift, faReceipt } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faVault } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faComments } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string/gql';
import { Button } from '../../components/buttons/Button';
import { MenuButton } from '../../components/buttons/MenuButton';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { BenefitItem } from '../../components/membership/BenefitItem';
import { EventsSection } from '../../components/membership/EventsSection';
import { ReceiptsSection } from '../../components/membership/ReceiptsSection';
import { Item } from '../../components/membership/shared';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { MembershipView } from '../../components/views/MembershipView';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { MembershipArtistDocument } from '../../graphql/generated/documents';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useMembership } from '../../hooks/useMembership';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  query MembershipArtist($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        membershipCardImage {
          id
          url
        }
        mainVaultId
      }
    }
  }
`);

const MEMBERSHIP_BENEFTIS = [
  {
    icon: faVault,
    title: 'Unlock my Vault',
    description: 'Get exclusive access to my new music, images, and videos.',
  },
  {
    icon: faTicket,
    title: 'Concert tickets',
    description: 'Be the first in line for tickets to my shows near you.',
  },
  {
    icon: faComments,
    title: 'Messaging',
    description: 'Join the conversation in a group chat with me and other fans.',
  },
];

export const MembershipPage = () => {
  const { isDesktop } = useWindow();
  const { loginStatus, loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { membership, isLoading } = useMembership({ artistHandle });

  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');
  const navigate = useNavigate();

  const { subscribeFreeTier } = useFreeTier();

  const now = useMemo(() => new Date(), []);

  const { data } = useQuery(MembershipArtistDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null && loginStatus === LoginStatus.LOGGED_OUT,
  });

  const unAuthArtist = data?.data.artistLink?.artist;

  const { artist, user, serialNumber, createdAt, points } = membership || {};
  const isOwner = !!artist?.mainVault.isUserArtistAdmin;

  const hasMembership = !!membership;
  useVaultTheme();

  const artistName = artist?.name ?? unAuthArtist?.name ?? artistHandle;
  const artistMembershipCardImage =
    artist?.membershipCardImage?.url ?? unAuthArtist?.membershipCardImage?.url;
  const vaultId = artist?.mainVault.id ?? unAuthArtist?.mainVaultId;
  const linkValue = artist?.linkValue ?? unAuthArtist?.linkValue;

  const username = user?.displayName ?? user?.username ?? 'username';
  const createdAtDate = createdAt ?? now.toISOString();

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      await subscribeFreeTier({ input: { vaultId, smsCampaignResponseShortcode } });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [linkValue, loginStatus, navigate, subscribeFreeTier, vaultId, smsCampaignResponseShortcode]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    membershipV2Enabled
      ? isOwner
      : loginStatus === LoginStatus.LOGGED_OUT || isOwner || membership === null
  ) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (membershipV2Enabled ? !membership && !unAuthArtist : membership === undefined) {
    return <FullPageLoading withVaultTheme />;
  }

  return (
    <DefaultLayout
      messageChannelId={undefined}
      withBottomNavigator
      headerLeft={!isDesktop ? <MenuButton className="h-12" withVaultTheme /> : null}
      vaultId={undefined}
      hasChatReadAccess={false}
      shouldSkipMargin={false}
      showBorder
      showRoundedTop
      withVaultTheme
      isHeaderTransparent
    >
      <MembershipView
        aspectRatio={0.8}
        isLoading={isLoading || (!membership && !unAuthArtist)}
        artistName={artistName ?? ''}
        serialNumber={serialNumber}
        imageUrl={artistMembershipCardImage}
        displayName={username}
        createdAt={createdAtDate}
        points={points}
      >
        {membershipV2Enabled ? (
          <View className="flex w-full flex-col gap-8 pb-10 lg:gap-10">
            {!hasMembership && (
              <Button
                label="Join for free"
                type="primary-themed"
                buttonType="submit"
                disabledClassName="opacity-30"
                className="w-full"
                onClick={onJoinFreeClick}
              />
            )}
            <EventsSection artistHandle={artistHandle} />

            <Item header="Membership benefits">
              {MEMBERSHIP_BENEFTIS.map(benefit => (
                <BenefitItem
                  key={benefit.title}
                  icon={benefit.icon}
                  benefitTitle={benefit.title}
                  benefitSubtext={benefit.description}
                />
              ))}
            </Item>
            <ReceiptsSection />
          </View>
        ) : (
          <View className="my-8 flex w-full flex-col items-center gap-2">
            <Text className="font-title text-[22px]/[26px] text-vault_text">Coming Soon</Text>
            <View
              className={twMerge(
                'mx-4 mt-4 flex flex-col gap-6 rounded-xl border p-10',
                'border-vault_text/5 bg-vault_text/10',
              )}
            >
              <View className="flex flex-col gap-4">
                <FontAwesomeIcon
                  icon={faReceipt}
                  size="2xl"
                  className="flex items-center text-vault_text"
                />
                <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
                  Collect receipts by pre-saving, streaming, and more.
                </Text>
              </View>

              <View className="h-[1px] w-full bg-vault_text/5" />

              <View className="flex flex-col gap-4">
                <FontAwesomeIcon
                  icon={faGift}
                  size="2xl"
                  className="flex items-center text-vault_text"
                />
                <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
                  The more receipts you collect, the more rewards & access you get.
                </Text>
              </View>
            </View>
          </View>
        )}
      </MembershipView>
    </DefaultLayout>
  );
};
