import { useRef, useState } from 'react';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useQuery } from '../../graphql/client';
import { VaultCustomizationDocument } from '../../graphql/generated';
import type { CustomizeVaultBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { CustomizeVaultForm } from '../menu/customize-vault/CustomizeVaultForm';

export function CustomizeVaultBottomsheet({
  vaultId,
  onClose,
  onSave,
}: CustomizeVaultBottomsheetProps) {
  const formRef = useRef<{ submit: () => void }>(null);
  const [isActionPending, setActionPending] = useState(false);

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  const { data, isLoading } = useQuery(VaultCustomizationDocument, {
    staleTime: 0,
    variables: {
      vaultId,
    },
    enabled: !!vaultId,
  });

  if (isLoading || data?.data.vaultFromId == null || !vaultId) {
    return null;
  }

  return (
    <View className="relative box-border flex w-full flex-col items-center gap-7 pb-24">
      <View className="z-10 sticky -top-2 box-border flex w-full flex-row items-center justify-between bg-black pb-7 pt-4">
        <div className="flex-1">
          <Button
            leadingIcon={faXmark}
            iconOnly
            onClick={onClose}
            disabled={isActionPending}
            label="Close"
            className="text-[24px] text-white"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>

        <div className="flex-1 text-center">
          <Text className="font-title text-title-m">Customize</Text>
        </div>

        <div className="flex flex-1 justify-end">
          <Button
            buttonType="submit"
            label=""
            labelComponent={
              <Text className="font-base text-base-l font-medium text-[#E3F41D]">Save</Text>
            }
            onClick={handleSubmit}
            disabled={isActionPending}
            disabledClassName="opacity-50"
          />
        </div>
      </View>
      <CustomizeVaultForm
        vaultId={vaultId}
        setActionPending={setActionPending}
        ref={formRef}
        onSave={onSave}
      />
    </View>
  );
}
