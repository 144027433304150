import React, { useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { debounce } from 'lodash-es';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { MetatagsHeader } from '../components/metatags/MetatagsHeader';
import { PresavePage } from '../components/presave/PresavePage';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useClickThroughSmsCampaign } from '../hooks/useClickThroughSmsCampaign';
import { useRedirectUserOnboarding } from '../hooks/useRedirectUserOnboarding';
import { MainProvider } from '../providers/MainProvider';
import { AboutPage } from '../screens/AboutPage';
import { AllVaultsPage } from '../screens/AllVaultsPage';
import { AnnouncementsPage } from '../screens/AnnouncementsPage';
import { ExplorePage } from '../screens/ExplorePage';
import { InvitesPage } from '../screens/InvitesPage';
import { LandingPage } from '../screens/LandingPage';
import { NotFoundPage } from '../screens/NotFoundPage';
import { PinnedMessagesScreen } from '../screens/PinnedMessagesScreen';
import { SeeDetailsMessageChannelPage } from '../screens/SeeDetailsMessageChannelPage';
import { SpotifyConnectPage } from '../screens/SpotifyConnect';
import { TextMe } from '../screens/TextPage';
import { OnboardingUsernamePage } from '../screens/auth/OnboardingUsernamePage';
import { SignInPage } from '../screens/auth/SignInPage';
import { VerifyPage } from '../screens/auth/VerifyPage';
import { SubscribePage } from '../screens/payment/SubscribePage';
import { SubscribeSuccessPage } from '../screens/payment/SubscribeSuccessPage';
import { AboutSettingsPage } from '../screens/settings/AboutSettingsPage';
import { AccountManagementPage } from '../screens/settings/AccountManagement';
import { AddPaymentMethodPage } from '../screens/settings/AddPaymentMethodPage';
import { ArtistNotificationSettingsPage } from '../screens/settings/ArtistNotificationSettingsPage';
import { BillingPage } from '../screens/settings/BillingPage';
import { EarningsBreakdownPage } from '../screens/settings/EarningsBreakdownPage';
import { EarningsPage } from '../screens/settings/EarningsPage';
import { EditArtistProfilePage } from '../screens/settings/EditArtistPage';
import { EditSubscriberProfilePage } from '../screens/settings/EditSubscriberProfilePage';
import { ManageArtistNotificationScreen } from '../screens/settings/ManageArtistNotificationScreen';
import { ManagePaymentMethodsPage } from '../screens/settings/ManagePaymentMethodsPage';
import { ManageSubscriptionPage } from '../screens/settings/ManageSubscriptionPage';
import { MySubscribersPage } from '../screens/settings/MySubscribersPage';
import { NotificationSettingsPage } from '../screens/settings/NotificationSettings';
import { PayoutsPage } from '../screens/settings/PayoutsPage';
import { PayoutsRefreshPage } from '../screens/settings/PayoutsRefreshPage';
import { SettingsPage } from '../screens/settings/SettingsPage';
import { SmsNotificationSettingsPage } from '../screens/settings/SmsNotificationSettings';
import { SubscriptionsPage } from '../screens/settings/SubscriptionsPage';
import { TestUserSettingsPage } from '../screens/settings/TestUserSettingsPage';
import { UpdatePaymentMethodPage } from '../screens/settings/UpdatePaymentMethodPage';
import { EditSnippetPage } from '../screens/vault/EditSnippetPage';
import { EditTrackPage } from '../screens/vault/EditTrackPage';
import { EditTrackSplitsPage } from '../screens/vault/EditTrackSplitsPage';
import { FinalizeTrackUploadPage } from '../screens/vault/FinalizeTrackUploadPage';
import { FolderPage } from '../screens/vault/FolderPage';
import { MediaLandingPage } from '../screens/vault/MediaLandingPage';
import { MediaUploadPage } from '../screens/vault/MediaUpload';
import { MembershipPage } from '../screens/vault/MembershipPage';
import { MoveToPage } from '../screens/vault/MoveToPage';
import { TrackLandingPage } from '../screens/vault/TrackLandingPage';
import { TrackSplitsPage } from '../screens/vault/TrackSplitsPage';
import { VaultMessageChannelPage } from '../screens/vault/VaultMessageChannelPage';
import { VaultPage } from '../screens/vault/VaultPage';
import { CreateAnnouncementPage } from '../screens/vault/announcement/Create';
import { ReviewAnnouncement } from '../screens/vault/announcement/Review';
import { CreateCampaignPage } from '../screens/vault/campaign/CreateCampaignPage';
import { SortVaultContent } from '../screens/vault/sortVault/SortVaultContent';
import { Sentry } from '../sentry';
import { LoginStatus } from '../types/authTypes';
import { sendPageAnalytics } from '../utils/analyticsUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../utils/subdomainUtils';

type Props = { children?: ReactNode };

export const MainRouter: FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <MainProvider>
        {children}

        <RoutesInternal />
      </MainProvider>
    </BrowserRouter>
  );
};

const useSubdomainNavigationHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const savedPathName = useRef(location.pathname);

  useEffect(() => {
    const handleSubdomainNavigation = () => {
      const currentPath = location.pathname;

      if (isValidSubdomain()) {
        const subdomain = getSubdomain();

        const routesToRemoveSubdomain = [
          ROUTES.VAULTS,
          ROUTES.EXPLORE,
          ROUTES.ABOUT,
          ROUTES.SETTINGS,
        ];

        const shouldRemoveSubdomain = routesToRemoveSubdomain.some(route =>
          currentPath.startsWith(route),
        );

        if (shouldRemoveSubdomain) {
          const newPath = currentPath.replace(subdomain + '.', '');
          const newUrl = `${window.location.protocol}//${window.location.host.replace(`${subdomain}.`, '')}${newPath}`;

          window.location.href = newUrl;
        }
      }
      savedPathName.current = currentPath;
    };

    handleSubdomainNavigation();
  }, [location.pathname, navigate]);
};

const useNavigationEvent = () => {
  const location = useLocation();
  const savedPathName = useRef(location.pathname);
  const previousAnalyticsPage = useRef(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sendAnalytics = debounce(
      () => {
        if (savedPathName.current !== location.pathname || previousAnalyticsPage.current === null) {
          const query: { [key: string]: string } = {};
          for (const entry of searchParams.entries()) {
            const key = entry[0];
            query[key] = entry[1];
          }

          sendPageAnalytics({
            subdomain: isValidSubdomain() ? getSubdomain() : null,
            pathname: location.pathname,
            previousAnalyticsPage: previousAnalyticsPage,
            searchParams: query,
          });

          savedPathName.current = location.pathname;
        }
      },
      10,
      { trailing: true },
    );

    sendAnalytics();

    return sendAnalytics.cancel;
  }, [location.pathname, searchParams]);
};

const useSmsCampaignResponseEvent = () => {
  const { clickThroughSmsCampaign } = useClickThroughSmsCampaign();
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');

  useEffect(() => {
    if (smsCampaignResponseShortcode) {
      clickThroughSmsCampaign({ shortcode: smsCampaignResponseShortcode }).catch(error => {
        Sentry.captureException(error, {
          extra: {
            message: 'Error in clickThroughSmsCampaign mutation',
            shortcode: smsCampaignResponseShortcode,
          },
          tags: {
            type: 'clickThroughSmsCampaignMutation',
          },
        });
      });
    }
  }, [clickThroughSmsCampaign, smsCampaignResponseShortcode]);
};

const generateVaultRoutes = (loginStatus: LoginStatus) => (
  <>
    <Route index element={<VaultPage />} />

    <Route
      path="upload"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <MediaUploadPage />
        )
      }
    />
    <Route
      path="finalize-upload"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <FinalizeTrackUploadPage />
        )
      }
    />
    <Route path="announcements">
      <Route index element={<AnnouncementsPage />} />
      <Route path="create" element={<CreateAnnouncementPage />} />
      <Route path="review" element={<ReviewAnnouncement />} />
    </Route>
    <Route path="campaign">
      <Route path="create" element={<CreateCampaignPage />} />
    </Route>
    <Route
      path="edit/:vaultContentId"
      element={
        loginStatus === LoginStatus.LOADING ? <FullPageLoading withVaultTheme /> : <EditTrackPage />
      }
    />
    <Route
      path="snippet/:vaultContentId"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <EditSnippetPage />
        )
      }
    />
    <Route
      path="splits/:vaultContentId"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <TrackSplitsPage />
        )
      }
    />
    <Route
      path="splits/:vaultContentId/add"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <EditTrackSplitsPage />
        )
      }
    />
    <Route path="chat">
      <Route index element={<VaultMessageChannelPage />} />
      <Route path="pins" element={<PinnedMessagesScreen />} />
      <Route path="details" element={<SeeDetailsMessageChannelPage />} />
    </Route>
    <Route path=":vaultContentId" element={<TrackLandingPage />} />
    <Route path="t/:vaultContentSlug" element={<TrackLandingPage />} />
    <Route path="subscribe" element={<SubscribePage />} />
    <Route path="success" element={<SubscribeSuccessPage />} />
    <Route path="invites" element={<InvitesPage />} />
    <Route path="folder/:folderId" element={<FolderPage />} />
    <Route path="f/:folderSlug" element={<FolderPage />} />
    <Route path="i/:vaultContentSlug" element={<MediaLandingPage />} />
    <Route path="v/:vaultContentSlug" element={<MediaLandingPage />} />
    <Route path="image/:vaultContentId" element={<MediaLandingPage />} />
    <Route path="video/:vaultContentId" element={<MediaLandingPage />} />
    <Route path="p/:campaignSlug" element={<PresavePage />} />
    <Route path="move-to/:folderId?" element={<MoveToPage />} />
    <Route path="rearrange/:folderId?" element={<SortVaultContent />} />
    <Route path="membership" element={<MembershipPage />} />
    <Route path="textme" element={<TextMe />} />
  </>
);

const RoutesInternal: FC = () => {
  const { loginStatus, isArtist, loggedInUser } = useAuthContext();

  useNavigationEvent();
  useRedirectUserOnboarding();
  useSubdomainNavigationHandler();
  useSmsCampaignResponseEvent();

  if (isValidSubdomain()) {
    return (
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
        <Route path={ROUTES.ONBOARDING}>
          <Route index element={<NotFoundPage />} />
          <Route path="username" element={<OnboardingUsernamePage />} />
        </Route>
        {generateVaultRoutes(loginStatus)}
        <Route path={ROUTES.EXPLORE} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.VAULTS} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.ABOUT} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.SETTINGS} element={<FullPageLoading withVaultTheme={false} />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
        <Route path={ROUTES.SPOTIFY_CONNECT} element={<SpotifyConnectPage />} />
        <Route
          path={ROUTES.VAULTS}
          element={
            loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme={false} />
            ) : loginStatus === LoginStatus.LOGGED_IN ? (
              <AllVaultsPage />
            ) : (
              <AllVaultsPage />
            )
          }
        />
        {loginStatus !== LoginStatus.LOGGED_OUT && (
          <>
            <Route path={ROUTES.ONBOARDING}>
              <Route index element={<NotFoundPage />} />
              <Route path="username" element={<OnboardingUsernamePage />} />
            </Route>
            <Route path={ROUTES.SETTINGS}>
              <Route index element={<SettingsPage />} />
              <Route path="notification-settings" element={<NotificationSettingsPage />} />
              <Route path="sms-notification-settings" element={<SmsNotificationSettingsPage />} />
              <Route path="test-user-settings" element={<TestUserSettingsPage />} />
              <Route path="artist-notification">
                <Route index element={<ArtistNotificationSettingsPage />} />
                <Route path=":artistId" element={<ManageArtistNotificationScreen />} />
              </Route>
              <Route path="account-management" element={<AccountManagementPage />} />
              <Route path="profile" element={<EditSubscriberProfilePage />} />
              {isArtist && <Route path="profile-artist" element={<EditArtistProfilePage />} />}
              <Route path="subscriptions">
                <Route index element={<SubscriptionsPage />} />
                <Route path=":subscriptionId" element={<ManageSubscriptionPage />} />
              </Route>
              <Route path="memberships">
                <Route index element={<SubscriptionsPage />} />
                <Route path=":subscriptionId" element={<ManageSubscriptionPage />} />
              </Route>
              <Route path="methods">
                <Route index element={<ManagePaymentMethodsPage />} />
                <Route path="add" element={<AddPaymentMethodPage />} />
                <Route path="update" element={<UpdatePaymentMethodPage />} />
              </Route>
              <Route path="billing" element={<BillingPage />} />
              <Route path="earnings" element={<EarningsPage isArtistPage={false} />} />
              <Route path="about-vault">
                <Route index element={<AboutSettingsPage />} />
                <Route path="about" element={<AboutPage />} />
              </Route>

              {isArtist && (
                <>
                  <Route path="artist-earnings" element={<EarningsPage isArtistPage />} />
                  <Route
                    path="artist-earnings-breakdown"
                    element={<EarningsBreakdownPage isArtistPage />}
                  />
                  <Route path="subscribers" element={<MySubscribersPage />} />
                  <Route path="members" element={<MySubscribersPage />} />
                  <Route path="payoutsrefresh" element={<PayoutsRefreshPage />} />
                </>
              )}
              <Route path="payouts" element={<PayoutsPage type="adaptive" />} />
              {loggedInUser?.payee && (
                <Route path="collaborator-payouts" element={<PayoutsPage type="collaborator" />} />
              )}
            </Route>
          </>
        )}
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
        <Route path="/:artistHandle">{generateVaultRoutes(loginStatus)}</Route>
        <Route path={ROUTES.EXPLORE} element={<ExplorePage />} />
        {loginStatus !== LoginStatus.LOADING && (
          <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
        )}
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route
          path={ROUTES.LANDING_PAGE}
          element={
            loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme={false} />
            ) : loginStatus !== LoginStatus.LOGGED_IN ? (
              <LandingPage />
            ) : loggedInUser?.artist?.linkValue != null ? (
              <Navigate to={artistNavigationPath(loggedInUser.artist.linkValue, '/')} replace />
            ) : (
              <Navigate to={ROUTES.VAULTS} replace />
            )
          }
        />
      </Routes>
      <MetatagsHeader />
    </>
  );
};
