import { type RefObject, useCallback, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  ArtistMainVaultViewFragmentDoc,
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
} from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';

import { setBadgeVisibility } from '../../hooks/useMembership';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Image } from '../common/Image';
import { View } from '../common/View';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { VaultContents } from '../vault/VaultContents';
import { VaultHeader } from '../vault/VaultHeader';

import { useVaultEngagement } from './hooks/useVaultEngagement';

gql(/* GraphQL */ `
  fragment artistMainVaultView on Artist {
    id
    name
    instagramHandle
    description
    customWebsiteUrl
    spotifyUrl
    tiktokHandle
    linkValue
    createdAt
    profileImage {
      id
      url
    }
    mainVault {
      id
      contentCount
      memberCount
      price
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      artist: artistProfile {
        id
        name
      }
      messageChannelId
      isUserArtistAdmin
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
  }

  query GetUserLastViewedTimeOnVault($vaultId: UUID!) {
    getUserLastViewedTimeOnVault(vaultId: $vaultId)
  }
`);

export function VaultView({
  artist,
  setShowProfileImage,
  scrollRef,
  showRightHeader,
}: {
  setShowProfileImage: (header: boolean) => void;
  artist: FragmentType<ArtistMainVaultViewFragmentDoc>;
  scrollRef: RefObject<HTMLDivElement>;
  showRightHeader: boolean;
}) {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { isDesktop } = useWindow();

  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { subscribeFreeTier } = useFreeTier();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const {
    id,
    name,
    profileImage,
    mainVault: { id: vaultId, activeSubscription, isUserArtistAdmin, tiers },
    linkValue,
  } = getFragment(ArtistMainVaultViewFragmentDoc, artist);

  const profileImageUrl = vaultTheme.profileImageUrl ?? profileImage?.url;

  // Update the last viewed time after the initial get query finishes
  useVaultEngagement({
    vaultId,
    triggerQuery: loggedInUser != null,
  });

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: activeSubscription,
    isOwner: isUserArtistAdmin,
  });
  const hasActiveSubscription = !!activeSubscription?.id;

  const chatAvailableForFreeUsers = tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const onJoinFreeClick = useCallback(async () => {
    if (isUserArtistAdmin) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      await subscribeFreeTier({
        input: { vaultId, inviteCode: invite, smsCampaignResponseShortcode },
      });
      if (!hasActiveSubscription) setBadgeVisibility(linkValue, vaultId, true);
      navigate(window.location.pathname, { replace: true });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        invite,
        c: smsCampaignResponseShortcode,
      });

      navigate(`/${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    isUserArtistAdmin,
    loginStatus,
    subscribeFreeTier,
    vaultId,
    invite,
    smsCampaignResponseShortcode,
    hasActiveSubscription,
    linkValue,
    navigate,
  ]);

  useEffect(() => {
    if (redirect === 'onboarding') {
      navigate(
        `${ROUTES.ONBOARDING}/username?artistHandle=${linkValue}&openBottomSheet=${bottomSheetType}`,
      );
    } else if (
      bottomSheetType === 'freeTierModal' &&
      loginStatus === LoginStatus.LOGGED_IN &&
      !activeSubscription &&
      !isUserArtistAdmin
    ) {
      onJoinFreeClick();
    }
  }, [
    activeSubscription,
    linkValue,
    loginStatus,
    navigate,
    onJoinFreeClick,
    bottomSheetType,
    redirect,
    isUserArtistAdmin,
  ]);

  return (
    <View
      className={twMerge(
        'relative flex min-h-full w-full flex-1 flex-col items-center text-white',
        isDesktop ? '-mt-20' : '-mt-32',
      )}
    >
      <View className="relative top-0 z-base aspect-square w-full overflow-clip">
        {profileImageUrl != null ? (
          <Image
            src={profileImageUrl}
            alt={name}
            className={twMerge(
              'z-below aspect-square w-full object-cover object-left-top',
              isDesktop && 'md2:rounded-t-[20px]',
            )}
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="z-below aspect-square w-full"
            svgClassName={isDesktop ? 'md2:rounded-t-[20px]' : undefined}
          />
        )}
        {showRightHeader && (
          <View
            className={twMerge(
              'absolute top-0 z-base aspect-square w-full bg-gradient-to-b from-vault_background to-transparent to-40%',
              isDesktop && 'md2:rounded-t-[20px]',
            )}
          />
        )}
        <View className="absolute top-20 z-base aspect-square w-full bg-gradient-to-b from-transparent to-vault_background to-80% md2:to-60%" />
      </View>

      <View className="z-above1 box-border w-full">
        <View className="-mt-[100px] box-border flex min-h-screen w-full flex-col bg-gradient-to-b from-transparent to-vault_text/3 px-4 py-6 md2:-mt-[290px] md2:px-6">
          <VaultHeader
            artist={artist}
            setShowProfileImage={setShowProfileImage}
            scrollRef={scrollRef}
            isOwner={isUserArtistAdmin}
            chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
            activeSubscriptionTier={activeSubscriptionFeatures?.tier}
            onJoinFreeClick={onJoinFreeClick}
          />

          <VaultContents
            vaultId={vaultId}
            artistLinkValue={linkValue}
            isOwner={isUserArtistAdmin}
            folderId={null}
          />
        </View>
      </View>
    </View>
  );
}
