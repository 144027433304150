/* eslint-disable react/iframe-missing-sandbox */
import { useCallback } from 'react';
import { RepeatMode } from '../../audio/AudioMeta';
import { useAppleMusicContext } from '../../contexts/AppleMusicContext';

import { useWindow } from '../../hooks/useWindow';
import { BottomAudioPlayer, DesktopBottomAudioPlayer } from './BottomAudioPlayer';

export function AppleMusicAudioPlayer({
  withBottomNavigator,
  withVaultTheme,
}: {
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
}) {
  const { isDesktop } = useWindow();
  const { musicKit, isPlaying, duration, currentTime, currentTrack } = useAppleMusicContext();

  //TODO: get song info from release campaign
  const artistName = 'Apple Music';
  const title = 'Apple Music';

  const togglePlayPause = useCallback(async () => {
    if (musicKit == null) return;

    if (musicKit.isPlaying) {
      musicKit.pause();
    } else {
      musicKit.play();
    }
  }, [musicKit]);

  const seek = useCallback(
    async (position: number) => {
      if (musicKit == null) return;

      musicKit.seekToTime(position);
    },
    [musicKit],
  );

  if (musicKit == null || currentTrack == null) {
    return null;
  }

  if (isDesktop) {
    return (
      <DesktopBottomAudioPlayer
        type="appleMusic"
        withVaultTheme={withVaultTheme}
        artistName={artistName}
        title={title}
        duration={duration}
        playing={isPlaying}
        repeatMode={
          musicKit.repeatMode === 0
            ? RepeatMode.NO_REPEAT
            : musicKit.repeatMode === 1
              ? RepeatMode.REPEAT_ONE
              : RepeatMode.REPEAT_ALL
        }
        togglePlayPause={togglePlayPause}
        position={currentTime}
        disableNextPrev
        isNextTrackDisabled
        loading={false}
        loadingActiveTrack={false}
        shuffleEnabled={false}
        seek={seek}
      />
    );
  }
  return (
    <BottomAudioPlayer
      type="appleMusic"
      withBottomNavigator={withBottomNavigator}
      withVaultTheme={withVaultTheme}
      subtitle={artistName}
      title={title}
      duration={duration}
      playing={isPlaying}
      togglePlayPause={togglePlayPause}
      position={currentTime}
      seek={seek}
      leftImageUrl={null}
    />
  );
}
