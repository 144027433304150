import linkifyit, { type FullRule } from 'linkify-it';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';

export const createNewLinkifyIt = () => {
  return new linkifyit();
};

export const addLinkifyItSchema = (linkifyIt: linkifyit, schema: string, rule: FullRule) => {
  linkifyIt.add(schema, rule);
};

export function generateShareLink({
  artistLinkValue,
  inviteCode = '',
  path = '',
}: {
  artistLinkValue: string | undefined | null;
  inviteCode: string | undefined | null;
  path: string | undefined | null;
}) {
  if (!artistLinkValue) return window.location.href;

  const url = new URL(window.location.href);
  const { host } = url;
  const isSubdomainPresent = host.startsWith(`${artistLinkValue}.`);

  if (IS_FINAL_DEPLOYMENT) {
    if (!isSubdomainPresent) {
      url.host = `${artistLinkValue}.${host}`;
    }
    url.pathname = path || '';
  } else {
    if (isSubdomainPresent) {
      url.host = host.replace(`${artistLinkValue}.`, '');
    }
    url.pathname = `/${artistLinkValue}${path || ''}`;
  }

  url.search = '';

  if (inviteCode) {
    url.searchParams.set('invite', inviteCode);
  }

  return url.href;
}

export function isValidSpotifyLink(url: string): boolean {
  const spotifyRegex = /^https:\/\/open\.spotify\.com\/(album|track)\/[a-zA-Z0-9]+(\?.*)?$/;
  return spotifyRegex.test(url);
}

export function isValidAppleMusicLink(url: string): boolean {
  const appleMusicRegex =
    /^https:\/\/music\.apple\.com\/[a-z]{2}\/(album|song)\/[^\/]+\/\d+(\?i=\d+)?$/;
  return appleMusicRegex.test(url);
}

export function extractAppleMusicResourceId(url: string | undefined | null): string | undefined {
  if (!url || !isValidAppleMusicLink(url)) return undefined;

  const urlObject = new URL(url);
  const pathParts = urlObject.pathname.split('/');
  const lastPathPart = pathParts[pathParts.length - 1];

  // Check for 'i' query parameter (track within album)
  const trackId = urlObject.searchParams.get('i');
  if (trackId) {
    return trackId;
  }

  // If no 'i' parameter, return the last numeric part of the path
  const match = lastPathPart?.match(/\d+/);
  return match ? match[0] : undefined;
}

export function extractSpotifyResourceId(url: string | undefined | null): string | undefined {
  if (!url || !isValidSpotifyLink(url)) return undefined;

  const urlObject = new URL(url);
  const pathParts = urlObject.pathname.split('/');

  // The ID is the last part of the pathname
  return pathParts[pathParts.length - 1];
}
