import { COLOR } from '../constants/colorConstants';
import { getTextColor } from '../hooks/useVaultTheme';

interface AccentColors {
  background: string;
  accents: string[];
}

export function generateAccentColors(backgroundColor: string): AccentColors {
  const bgHsl = rgbToHsl(...hexToRgb(backgroundColor));

  if (isGrayScale(backgroundColor)) {
    if (getTextColor(backgroundColor) === COLOR.white) {
      return {
        background: backgroundColor,
        accents: [COLOR.orange200, COLOR.yellow100, COLOR.green150, COLOR.blue200, COLOR.white],
      };
    } else {
      return {
        background: backgroundColor,
        accents: [COLOR.orange200, COLOR.purple100, COLOR.green400, COLOR.secondary, COLOR.base900],
      };
    }
  }

  const accents = [
    adaptiveTint(bgHsl, 0.3),
    adaptiveTint(bgHsl, 0.15),
    adaptiveShade(bgHsl),
    ensureContrast(complementary(bgHsl), bgHsl),
    ensureContrast(triadicHarmony(bgHsl), bgHsl),
  ];

  return {
    background: backgroundColor,
    accents: accents.map(hslToHex),
  };
}

function adaptiveTint(hsl: HSL, baseAmount: number): HSL {
  const amount = baseAmount - hsl.l * baseAmount; // More tint for darker colors
  return { ...hsl, l: clamp(hsl.l + amount, 0, 1) };
}

function adaptiveShade(hsl: HSL): HSL {
  const amount = 0.1 + (1 - hsl.l) * 0.3; // More shade for lighter colors
  return { ...hsl, l: clamp(hsl.l - amount, 0, 1) };
}

function complementary(hsl: HSL): HSL {
  return { ...hsl, h: (hsl.h + 0.5) % 1 };
}

function triadicHarmony(hsl: HSL): HSL {
  return { ...hsl, h: (hsl.h + 1 / 3) % 1 };
}

function ensureContrast(color: HSL, background: HSL): HSL {
  const contrast = Math.abs(color.l - background.l);
  if (contrast < 0.3) {
    color.l = clamp(background.l + (background.l < 0.5 ? 0.3 : -0.3), 0, 1);
  }
  return color;
}

type HSL = { h: number; s: number; l: number };

function hexToRgb(hex: string): [number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1]!, 16), parseInt(result[2]!, 16), parseInt(result[3]!, 16)]
    : [0, 0, 0];
}

function rgbToHsl(r: number, g: number, b: number): HSL {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hslToHex(hsl: HSL): string {
  const rgb = hslToRgb(hsl.h, hsl.s, hsl.l);
  return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function isGrayScale(hex: string): boolean {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return r === g && g === b;
}
