import { useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isMobile } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { Button } from '../../../components/buttons/Button';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';

import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { VaultItemRow } from '../../../components/vault/items/VaultItemRow';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  type FragmentType,
  getFragment,
  GetTrackIdsToPopulateQueueDocument,
  SortVaultContentDocument,
  VaultContentByFolderPositionDocument,
  VaultItemFragmentDoc,
} from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import {
  clearVaultContentCustomPage,
  useVaultContentByFolderPosition,
} from '../../../hooks/useVaultContent';
import { useVaultTheme } from '../../../hooks/useVaultTheme';

gql(/* GraphQL */ `
  mutation SortVaultContent($input: MutationSetVaultContentOrderInput!) {
    setVaultContentOrder(input: $input) {
      __typename
      ... on MutationSetVaultContentOrderSuccess {
        data
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [SortVaultContentDocument],
  refetch: [VaultContentByFolderPositionDocument, GetTrackIdsToPopulateQueueDocument],
  throttled: '500ms',
});

const vaultContentItem = (_: number, vaultItem: FragmentType<VaultItemFragmentDoc>) => {
  const { id: contentId } = getFragment(VaultItemFragmentDoc, vaultItem);
  return <SortableItem key={contentId} id={contentId} item={vaultItem} />;
};
export const SortVaultContent = () => {
  const { loggedInUser } = useAuthContext();
  const { folderId } = useParams<{
    folderId: string | undefined;
  }>();

  useVaultTheme();

  const { openToast } = useToast();

  const navigate = useNavigate();

  const { mutateAsync } = useMutation(SortVaultContentDocument, {
    onSuccess: () => {
      navigate(-1);
      openToast({
        text: 'Changes saved!',
        variant: 'success',
      });
    },
  });

  const { artistHandle } = useArtistHandle();

  const mainVaultId = loggedInUser?.adminArtists?.find(
    artist => artist.artistLinkValue === artistHandle,
  )?.artistMainVaultId;

  const sensors = useSensors(
    isMobile ? null : useSensor(PointerSensor),
    isMobile
      ? null
      : useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const { orderedList, fetchNextPage, hasNextPage, isInitialLoading, entityStore } =
    useVaultContentByFolderPosition({
      vaultId: mainVaultId,
      folderId,
      pageSize: 15,
    });

  const [activeItem, setActiveItem] = useState<
    | (FragmentType<VaultItemFragmentDoc> & {
        id: string;
      })
    | undefined
    | null
  >(null);
  const getIndex = (id: string) => {
    const item = getActiveItem(id);
    if (!item) return -1;
    return item.folderPosition;
  };

  const getActiveItem = (id: string) => entityStore.nodes[id];
  const activeIndex = activeItem ? getIndex(activeItem.id) : -1;

  const onEndReached = useStableCallback(() => {
    if (!hasNextPage) return;

    fetchNextPage();
  });

  const bottomNav = (
    <View
      className={twMerge(
        'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 border-t border-solid py-2',
        'border-t-vault_text/5 bg-vault_background',
      )}
    >
      <View className="flex w-full items-center justify-between px-8 md2:px-16">
        <Button
          label="Cancel"
          type="secondary"
          className="w-fit rounded-full px-4 py-3.5 text-[14px]/[18px] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            if (mainVaultId) {
              clearVaultContentCustomPage({ vaultId: mainVaultId, folderId: folderId ?? null });
            }
            navigate(-1);
          }}
        />
        <Button
          label="Done"
          type="primary-themed"
          className="w-fit rounded-full px-4 py-3.5 text-[14px]/[18px] disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            if (mainVaultId != null) {
              mutateAsync({
                input: {
                  vaultId: mainVaultId,
                  parentVaultContentId: folderId,
                  contentIds: orderedList.map(i => i.id),
                },
              });
            }
          }}
        />
      </View>
    </View>
  );

  const memoizedFooter = useMemo(() => {
    if (!hasNextPage) return null;
    return <View className="flex w-full max-w-content flex-col">{LOADING_ITEMS}</View>;
  }, [hasNextPage]);

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      messageChannelId={undefined}
      vaultId={mainVaultId}
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{bottomNav}</View>}
      hasChatReadAccess
      showBorder
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerCenter={
        <Text className="line-clamp-1 text-left font-title text-[18px]/[20px] font-medium text-vault_text">
          Rearrange Items
        </Text>
      }
      footer={<View className="hidden w-full md2:block">{bottomNav}</View>}
    >
      <View className="flex h-full min-h-full w-full max-w-content">
        {isInitialLoading ? (
          <View className="flex w-full max-w-content flex-col">{LOADING_ITEMS}</View>
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
              const item = getActiveItem(active.id.toString());
              setActiveItem(item);
            }}
            onDragEnd={({ over }) => {
              if (over) {
                const overIndex = getIndex(over.id.toString());
                if (activeIndex !== overIndex) {
                  const arr = arrayMove(orderedList, activeIndex, overIndex);
                  arr.forEach((item, index) => {
                    const entity = entityStore.nodes[item.id];
                    if (entity != null) {
                      entity.folderPosition = index;
                      entityStore.nodes[item.id] = entity;
                    }
                  });
                }
              }

              setActiveItem(null);
            }}
            onDragCancel={() => setActiveItem(null)}
          >
            <SortableContext items={orderedList} strategy={verticalListSortingStrategy}>
              <Virtuoso
                useWindowScroll
                data={orderedList}
                itemContent={vaultContentItem}
                className="scrollbar-dark flex w-full max-w-content flex-shrink"
                endReached={onEndReached}
                increaseViewportBy={500}
                components={{ Footer: () => memoizedFooter }}
              />
            </SortableContext>

            {createPortal(
              <DragOverlay>
                {!!activeItem ? (
                  <View className="flex w-full">
                    <VaultItemRow item={activeItem} />
                  </View>
                ) : null}
              </DragOverlay>,
              document.body,
            )}
          </DndContext>
        )}
      </View>
    </DefaultLayout>
  );
};

const SortableItem = ({ item, id }: { item: FragmentType<VaultItemFragmentDoc>; id: string }) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: id,
  });

  const isActive = active?.id === id;

  return (
    <div
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      ref={setNodeRef}
      className="flex flex-shrink py-2"
      {...listeners}
      {...attributes}
    >
      <VaultItemRow item={item} isActive={isActive} />
    </div>
  );
};

function Loading() {
  return (
    <View className="flex min-h-[71px] w-full py-2">
      <LoadingSkeleton className="h-full w-full bg-vault_text/10" />
    </View>
  );
}

const LOADING_ITEMS = Array.from({ length: 10 }).map((_, i) => (
  <Loading key={`${i}-Sort-Skel-Item`} />
));
