import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';

export const Item = ({
  header,
  headerLink,
  children,
}: {
  header: string;
  headerLink?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <View className="flex flex-col gap-6">
      <View className="flex w-full items-center justify-between">
        <Text className="font-title text-[18px]/[20px] text-vault_text lg:text-[24px]/[26px]">
          {header}
        </Text>
        {headerLink}
      </View>

      {children}
    </View>
  );
};
