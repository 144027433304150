import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { COLOR } from '../../../constants/colorConstants';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  ArtistByHandleDocument,
  CustomizeVaultDocument,
  CustomizeVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
  VaultCustomizationDocument,
  VaultThemeByArtistHandleDocument,
  VaultThemeByVaultIdDocument,
} from '../../../graphql/generated';
import { useUploadAvatar } from '../../../hooks/useUpdateAvatar';
import { setVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { generateAccentColors } from '../../../utils/colorUtils';
import { sleep } from '../../../utils/timerUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';
import { UserProfileImage } from '../../user/UserProfileImage';
import { DesktopColorPickers, MobileColorPickers } from './ColorPickers';
import { LogoUpload } from './LogoUpload';

RefetchOnComplete({
  trigger: [CustomizeVaultDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultThemeByArtistHandleDocument,
    VaultThemeByVaultIdDocument,
    VaultCustomizationDocument,
    CustomizeVaultViewFragmentDoc,
  ],
});

const validationSchema = z.object({
  profileImageMediaId: z.string().nullish(),
  accentColor: z.string().nullish(),
  backgroundColor: z.string().nullish(),
  logoMediaId: z.string().nullish(),
  shouldRemoveLogo: z.boolean(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

gql(/* GraphQL */ `
  fragment customizeVaultView on Vault {
    id
    accentColor
    backgroundColor
    artistId
    artistProfile {
      id
      profileImage {
        id
        url
        mediaType
      }
    }
    logoImage {
      id
      url
      mediaType
    }
  }

  mutation CustomizeVault($input: MutationCustomizeVaultInput!) {
    customizeVault(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationCustomizeVaultSuccess {
        data {
          id
          ...customizeVaultView
        }
      }
    }
  }

  query VaultCustomization($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      ...customizeVaultView
    }
  }
`);

export const CustomizeVaultForm = forwardRef(
  (
    {
      vaultId,
      setActionPending,
      onSave,
    }: {
      vaultId: string;
      setActionPending: (isPending: boolean) => void;
      onSave: () => void;
    },
    ref,
  ) => {
    const { data, isLoading } = useQuery(VaultCustomizationDocument, {
      staleTime: 0,
      cacheTime: 0,
      variables: { vaultId },
    });

    if (isLoading || data?.data.vaultFromId == null) {
      return <CustomizeVaultSkeleton />;
    }

    return (
      <FormFields
        ref={ref}
        initialData={data.data.vaultFromId}
        setActionPending={setActionPending}
        onSave={onSave}
      />
    );
  },
);

const FormFields = forwardRef(
  (
    {
      initialData,
      setActionPending,
      onSave,
    }: {
      initialData: FragmentType<CustomizeVaultViewFragmentDoc>;
      setActionPending: (isPending: boolean) => void;
      onSave: () => void;
    },
    ref,
  ) => {
    const vaultFrag = getFragment(CustomizeVaultViewFragmentDoc, initialData);
    const vaultId = vaultFrag.id;

    const { pathname } = useLocation();
    const { openToast } = useToast();
    const { isDesktop } = useWindow();

    const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | undefined>(
      vaultFrag.artistProfile?.profileImage?.url,
    );
    const [temporaryLogoUrl, setTemporaryLogoUrl] = useState<string | undefined>(
      vaultFrag.logoImage?.url,
    );

    const [updatePending, setUpdatePending] = useState<boolean>(false);
    const [openPicker, setOpenPicker] = useState<'background' | 'accent' | null>(null);
    const [showSuggestedAccents, setShowSuggestedAccents] = useState(false);

    const { getRootProps, getInputProps } = useUploadAvatar({
      onConfirm: () => setUpdatePending(true),
      onDone: async () => {
        await sleep(400);
        setUpdatePending(false);
      },
      onSuccess({ mediaId, cdnUrl }) {
        setTemporaryProfileImageUrl(cdnUrl);
        setValue('profileImageMediaId', mediaId, { shouldDirty: true });
      },
    });

    const {
      handleSubmit,
      formState: { isSubmitting },
      setValue,
      control,
      reset,
    } = useForm<ValidationSchema>({
      defaultValues: {
        ...vaultFrag,
        profileImageMediaId: vaultFrag.artistProfile?.profileImage?.id ?? null,
        logoMediaId: vaultFrag.logoImage?.id ?? null,
        shouldRemoveLogo: false,
      },
      resolver: zodResolver(validationSchema),
    });

    // Expose the submit function to the parent component
    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
    }));

    const { mutateAsync: customizeVault, isLoading: isCustomizing } = useMutation(
      CustomizeVaultDocument,
      {
        onError: e => {
          openToast({
            text: e.message,
            variant: 'error',
          });
        },
      },
    );

    const isActionPending = isSubmitting || updatePending || isCustomizing;

    useEffect(() => {
      setActionPending(isActionPending);
    }, [isActionPending, setActionPending]);

    const { accentColor: formAccentColor, backgroundColor: formBackgroundColor } = useWatch({
      control,
    });

    useEffect(() => {
      // preview form logic mirroring useVaultTheme
      const backgroundColor: string = formBackgroundColor ?? COLOR.black;
      const accentColor: string = formAccentColor ?? COLOR.yellow100;

      setVaultTheme({
        newAccentColor: accentColor,
        newBackgroundColor: backgroundColor,
        newLogoUrl: temporaryLogoUrl ?? null,
        newProfileImageUrl: temporaryProfileImageUrl ?? null,
      });
    }, [formAccentColor, formBackgroundColor, temporaryLogoUrl, temporaryProfileImageUrl]);

    const accents = useMemo(
      () => (formBackgroundColor ? generateAccentColors(formBackgroundColor).accents : null),
      [formBackgroundColor],
    );

    const onSubmit = async (props: ValidationSchema) => {
      trackEvent({
        type: EVENTS.CUSTOMIZE_VAULT,
        properties: { vaultId },
        pathname,
      });

      const result = await customizeVault({
        input: {
          vaultId,
          profileImageId: props.profileImageMediaId,
          accentColor: props.accentColor ?? vaultFrag.accentColor,
          backgroundColor: props.backgroundColor ?? vaultFrag.backgroundColor,
          logoImageId: props.logoMediaId,
          shouldRemoveLogo: props.shouldRemoveLogo,
        },
      });

      if (result.data.customizeVault.__typename === 'MutationCustomizeVaultSuccess') {
        onSave();

        // Reset form on Success
        reset();
        setTemporaryLogoUrl(undefined);
        setTemporaryProfileImageUrl(undefined);

        openToast({
          text: 'Vault customization saved successfully!',
          variant: 'success',
        });
      } else {
        openToast({
          text: 'Failed to save vault customization. Please try again.',
          variant: 'error',
        });
      }
    };

    const rootProps = getRootProps();

    const memoizedSuggestedAccents = useMemo(() => {
      return (
        <View className="relative animate-fadeIn gap-4 rounded-[20px] bg-base800 px-7 py-5">
          <div className="flex flex-row items-start justify-between">
            <div className="mr-[10px]">
              <Text className="font-title text-title-s">Suggested accent colors</Text>

              <Text className="font-base text-base-m text-base500">
                Works best with your background
              </Text>
            </div>
            <Button
              leadingIcon={faXmark}
              iconOnly
              onClick={() => {
                setShowSuggestedAccents(false);
              }}
              label="Close"
              className="text-[24px] text-white"
              disabledClassName="opacity-50 cursor-not-allowed"
            />
          </div>
          <View className="mt-5 flex flex-row items-start gap-4">
            {accents?.map(color => (
              <div
                key={color}
                className={twMerge(
                  'flex h-7 w-7 rounded-full border border-solid border-white hover:cursor-pointer',
                )}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setValue('accentColor', color, { shouldDirty: true });
                }}
              />
            ))}
          </View>
        </View>
      );
    }, [accents, setValue]);

    if (!vaultFrag.artistId) {
      return null;
    }

    return (
      <View className="flex w-full flex-col gap-6">
        <View className="flex w-full flex-row items-center gap-3">
          {updatePending ? (
            <SkeletonProfilePicture />
          ) : (
            <UserProfileImage
              profileImageUrl={
                temporaryProfileImageUrl ?? vaultFrag.artistProfile?.profileImage?.url ?? undefined
              }
              className="h-[100px]"
              withVaultTheme
            />
          )}
          <div
            className={twMerge('w-full', updatePending ? 'cursor-none' : 'cursor-pointer')}
            {...rootProps}
            onClick={(...args) => {
              if (updatePending) return;
              rootProps.onClick?.(...args);
            }}
          >
            <Button
              label="Choose image"
              type="primary"
              className="w-full bg-base700 text-white"
              disabled={updatePending}
              disabledClassName="opacity-30 transition-opacity"
            />
            <input {...getInputProps()} disabled={updatePending} />
          </div>
        </View>

        <LogoUpload
          artistId={vaultFrag.artistId}
          savedLogoUrl={temporaryLogoUrl ?? vaultFrag.logoImage?.url}
          onLogoUpload={media => {
            setTemporaryLogoUrl(media.cdnUrl);
            setValue('logoMediaId', media.mediaId, { shouldDirty: true });
            setValue('shouldRemoveLogo', false, { shouldDirty: true });
          }}
          onLogoRemove={() => {
            setTemporaryLogoUrl(undefined);
            setValue('logoMediaId', null, { shouldDirty: true });
            setValue('shouldRemoveLogo', true, { shouldDirty: true });
          }}
        />

        {isDesktop ? (
          <DesktopColorPickers
            backgroundColor={formBackgroundColor || COLOR.black}
            accentColor={formAccentColor || COLOR.yellow100}
            openPicker={openPicker}
            setOpenPicker={setOpenPicker}
            setShowSuggestedAccents={setShowSuggestedAccents}
            setColor={color => {
              if (openPicker) {
                setValue(openPicker === 'accent' ? 'accentColor' : 'backgroundColor', color, {
                  shouldDirty: true,
                });
              }
            }}
          />
        ) : (
          <MobileColorPickers
            backgroundColor={formBackgroundColor || COLOR.black}
            accentColor={formAccentColor || COLOR.yellow100}
            setShowSuggestedAccents={setShowSuggestedAccents}
            setBackgroundColor={color => {
              setValue('backgroundColor', color, {
                shouldDirty: true,
              });
            }}
            setAccentColor={color => {
              setValue('accentColor', color, {
                shouldDirty: true,
              });
            }}
          />
        )}

        {showSuggestedAccents && !!accents && memoizedSuggestedAccents}
      </View>
    );
  },
);

function CustomizeVaultSkeleton() {
  const { isDesktop } = useWindow();
  return (
    <View
      className={twMerge(
        'z-above1 flex w-full flex-1 flex-col items-center text-white',
        !isDesktop && 'sm:w-[66%] md:w-[50%] lg:w-[33%]',
      )}
    >
      <SkeletonProfilePicture />
      <LoadingSkeleton className="mb-[20px] h-[30px] w-[60px]" />
      <View className="box-border flex w-full flex-1 flex-col bg-black px-[16px]">
        <LoadingSkeleton className="mb-[28px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
        <LoadingSkeleton className="mb-[28px] mt-[20px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
      </View>
    </View>
  );
}

function SkeletonProfilePicture({ className }: { className?: string }) {
  return (
    <LoadingSkeleton
      className={className ?? 'mb-[12px] h-[100px] w-[100px] flex-shrink-0 rounded-full'}
    />
  );
}
