import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { loadTrack, PlayerType, useAudioController } from '../../../audio/AudioController';
import { togglePlayPause } from '../../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../../audio/AudioPosition';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useFreeTier } from '../../../hooks/useFreeTier';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { constructQueryParams } from '../../../utils/stringUtils';

let firstClick = true;

export const useTrackFilePress = ({
  vaultId,
  trackId,
  isLocked,
  showUpsellInterstitial,
  folderId,
  artistHandle,
  vaultArtistProfileImage,
  hasActiveSubscription,
}: {
  vaultId: string;
  trackId: string;
  isLocked: boolean;
  showUpsellInterstitial: boolean;
  folderId: string | null;
  artistHandle: string;
  vaultArtistProfileImage: string;
  hasActiveSubscription: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const { activeTrackId, playing, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const { pathname } = useLocation();
  const { openBottomsheet } = useBottomsheetContainer();
  const { subscribeFreeTier } = useFreeTier();
  const navigate = useNavigate();
  const { loggedInUser } = useAuthContext();

  const inviteCode = searchParams.get('code');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const onPlayClick = () => {
    if (isLocked) {
      trackEvent({
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: 'JOIN_VAULT',
          joinType: 'free',
          artistHandle,
        },
      });

      openBottomsheet({
        type: BOTTOMSHEET_TYPES.JOIN_VAULT,
        shared: {
          withVaultTheme: true,
        },
        joinVaultBottomsheetProps: {
          artistHandle,
          vaultArtistProfileImage,
          joinType: 'free',
          buttonTitle: 'Join for free',
          title: 'Join this vault to get started',
          onConfirm() {
            if (hasActiveSubscription) {
              const queryParams = constructQueryParams({
                code: inviteCode,
                c: smsCampaignResponseShortcode,
              });
              navigate(artistNavigationPath(artistHandle, '/subscribe', queryParams));
              return;
            }

            // If logged in, subscribe to free tier
            if (!!loggedInUser?.id) {
              subscribeFreeTier({
                input: { vaultId, inviteCode, smsCampaignResponseShortcode },
              });
              return;
            }

            // If not logged in, take to sign in page
            navigate(
              `${ROUTES.SIGN_IN}?${constructQueryParams({ artistHandle, inviteCode, c: smsCampaignResponseShortcode, openBottomSheet: 'freeTierModal' })}`,
            );
          },
        },
      });
      return;
    }

    const playSound = () => {
      PlayerType.current = 'vault';
      if (activeTrackId !== trackId) {
        loadTrack({
          trackId,
          vaultId,
          component: 'artist_page',
          folderId,
        });

        if (firstClick) {
          firstClick = false;
          setTimeout(() => {
            loadTrack({
              trackId,
              vaultId,
              component: 'artist_page',
              folderId,
            });
          }, 200);
        }
      } else {
        activeTrack != null &&
          trackEvent({
            type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
            properties: {
              trackId: activeTrack.id,
              vaultId: activeTrack.vault.id,
              artistId: activeTrack.vault.artist?.id,
              percentComplete,
              isPreview: !activeTrack.isFullVersionAvailable,
              component: 'artist_page',
            },
            pathname,
          });

        togglePlayPause();
      }
    };

    playSound();

    if (showUpsellInterstitial) {
      setTimeout(() => {
        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_track',
            onContinue: playSound,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_track',
          },
        });
      }, 2000);
    }
  };

  return {
    onPlayClick,
  };
};
