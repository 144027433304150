import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistCampaignsDocument } from '../../graphql/generated/documents';
import { LoginStatus } from '../../types/authTypes';
import { getManyFromList } from '../../utils/arrayUtils';
import { EventItem, EventItemSkeleton } from './EventItem';
import { Item } from './shared';

gql(/* GraphQL */ `
  query ArtistCampaigns($artistHandle: String!) {
    releaseCampaignsByArtistHandle(artistHandle: $artistHandle, first: 3, sort: ASC) {
      __typename

      edges {
        node {
          id
          ...EventItem
        }
      }
    }
  }
`);

export const EventsSection = ({ artistHandle }: { artistHandle: string }) => {
  const { loginStatus } = useAuthContext();
  const { data: events = [], isInitialLoading } = useQuery(ArtistCampaignsDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      artistHandle,
    },
    // TODO: Remove loginStatus check when artist campaigns are public
    enabled: artistHandle != null && loginStatus !== LoginStatus.LOGGED_OUT,

    select: data =>
      getManyFromList(data.data.releaseCampaignsByArtistHandle.edges, ({ node }) => node),
  });

  const hasEvents = events.length > 0;

  if (isInitialLoading) {
    return (
      <Item header="New events">
        {Array.from({ length: 3 }).map((_, index) => (
          <EventItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  if (!hasEvents) {
    return null;
  }

  return (
    <Item
      header="New events"
      // TODO: Add link when page is available
      // headerLink={
      // <Link
      //   to="/"
      //   className="text-base text-[14px]/[16px] text-vault_text/50 no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
      // >
      //   See all
      // </Link>
      // }
    >
      {events.map(event => (
        <EventItem key={event.id} eventData={event} />
      ))}
    </Item>
  );
};
