import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { z } from 'zod';
import { gql } from '@soundxyz/gql-string';

import { setHideAudioPlayer } from '../audio/AudioMeta';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { SeeDetailsMessageChannelView } from '../components/views/SeeDetailsMessageChannelView';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import {
  getFragment,
  GetMessageChannelDocument,
  SeeDetailsHeaderFragmentDoc,
} from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import { artistNavigationPath } from '../utils/navigationUtils';
import { PersistenceStorage } from '../utils/storeUtils';

gql(/* GraphQL */ `
  query GetMessageChannel($input: QueryMessageChannelByArtistHandleInput!) {
    messageChannelByArtistHandle(input: $input) {
      ...SeeDetailsHeader
    }
  }
`);

export const TabSchema = z.enum(['artist', 'pinned', 'attachments']);
export type SeeDetailsTab = z.infer<typeof TabSchema>;
export const DetailsTabStore = PersistenceStorage({
  schema: z.record(z.string(), TabSchema),
  key: 'detailsTab',
  eager: true,
});

export const SeeDetailsMessageChannelPage = () => {
  const { loginStatus } = useAuthContext();
  const [isAtTop, setIsAtTop] = useState(true);
  const { isDesktop } = useWindow();

  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const { data, isLoading, isError, refetch } = useQuery(GetMessageChannelDocument, {
    variables: !!artistHandle && { input: { artistHandle } },
    enabled: artistHandle != null && loginStatus === LoginStatus.LOGGED_IN,
    staleTime: 0,
  });

  useEffect(() => {
    if (!isDesktop) {
      setHideAudioPlayer(true);
    }

    return () => {
      if (!isDesktop) {
        setHideAudioPlayer(false);
      }
    };
  }, [isDesktop]);

  if (isError || artistHandle == null) {
    return (
      <DefaultLayout
        withVaultTheme
        showRoundedTop
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={<BackButton className="text-vault_text" />}
        isHeaderTransparent={false}
        contentClassName="md2:bg-vault_text/3"
        stretch
      >
        <ErrorView
          onRetryClick={refetch}
          loggingType="SeeDetailsMessageChannelPage"
          className="flex-1"
          withVaultTheme
        />
      </DefaultLayout>
    );
  }

  if (loginStatus !== LoginStatus.LOADING && data == null && !isLoading) {
    return <Navigate to={artistNavigationPath(artistHandle, '/chat')} />;
  }

  const artistName =
    getFragment(SeeDetailsHeaderFragmentDoc, data?.data.messageChannelByArtistHandle)?.vault
      ?.artistProfile?.name ?? '';
  const artistLinkValue =
    getFragment(SeeDetailsHeaderFragmentDoc, data?.data.messageChannelByArtistHandle)?.vault
      ?.artistProfile?.linkValue ?? '';

  const tab: SeeDetailsTab = DetailsTabStore.useStore().value?.[artistLinkValue || '_'] || 'artist';

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton className="mt-2 text-vault_text" />}
      stretch
      childrenWrapperClassName="px-0"
      contentClassName="md2:bg-vault_text/3"
      headerCenter={
        !isDesktop &&
        !isAtTop && (
          <Text className="mt-2 font-title !text-title-l text-vault_text">{artistName}</Text>
        )
      }
    >
      <SeeDetailsMessageChannelView
        isHeaderLoading={isLoading || loginStatus === LoginStatus.LOADING}
        messageChannel={data?.data.messageChannelByArtistHandle ?? null}
        tab={tab}
        artistHandle={artistLinkValue}
        isAtTop={isAtTop}
        setIsAtTop={setIsAtTop}
      />
    </DefaultLayout>
  );
};
