import { type ChangeEventHandler, type FC, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { Logo } from '../../components/svg/Logo';
import { useAttachPayee } from '../../components/views/hooks/useAttachPayee';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { invalidateOperations, useMutation } from '../../graphql/client';
import { AuthUserDocument, UpdateUserOnboardingDocument } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { removeInvalidUsernameChars, usernameSchema } from '../../utils/username';

gql(/* GraphQL */ `
  mutation UpdateUserOnboarding($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserSuccess {
        data {
          id
          username
        }
      }
    }
  }
`);

const validationSchema = z.object({
  newUsername: usernameSchema,
});
type ValidationSchema = z.infer<typeof validationSchema>;

export const OnboardingUsernamePage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { mutateAsync } = useMutation(UpdateUserOnboardingDocument, {});
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const artistHandle = searchParams.get('artistHandle');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { attachPayeeIfPresent } = useAttachPayee();

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      bottomSheetType,
    }: {
      artistHandle?: string | null;
      bottomSheetType?: string | null;
    }): string => {
      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          openBottomSheet: bottomSheetType,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
        });

        return artistNavigationPath(artistHandle, '/', queryParams);
      }

      return ROUTES.VAULTS;
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
  } = useForm<ValidationSchema>({
    defaultValues: {
      newUsername: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const isSubmitDisabled = isSubmitting;

  useEffect(() => {
    if (loggedInUser?.username != null) {
      setValue('newUsername', loggedInUser.username);
    }
  }, [loggedInUser?.username, setValue]);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue('newUsername', removeInvalidUsernameChars(e.target.value.toLowerCase().trim()));
  };

  const errorText = errors.newUsername?.message;

  const onSubmit: SubmitHandler<ValidationSchema> = async (data, e) => {
    e?.preventDefault();

    const newUsername = data.newUsername.trim().toLowerCase();

    if (newUsername.length < 3) {
      setError('newUsername', {
        message: 'Username must be at least 3 characters',
      });
      return;
    }

    try {
      setIsLoading(true);
      const result = await mutateAsync({ input: { newUsername } });

      if (result.data.updateUser.__typename === 'MutationUpdateUserSuccess') {
        mixpanelClient.people.set({ name: newUsername });

        await invalidateOperations({ operations: [AuthUserDocument] });
        setIsLoading(false);

        const navigateTo = navigationPath({
          artistHandle,
          bottomSheetType,
        });

        attachPayeeIfPresent({
          onError() {
            navigate(navigateTo);
          },
          onWithoutPayee() {
            navigate(navigateTo);
          },
        });
      } else if (result.data.updateUser.__typename === 'ValidationError') {
        setIsLoading(false);
        setError('newUsername', {
          message: 'Only lowercase letters, numbers, hyphens, and underscores',
        });
        return;
      } else if (result.data.updateUser.__typename === 'UsernameUnavailableError') {
        setIsLoading(false);
        setError('newUsername', {
          message: 'Username is not available, please try again',
        });
      } else {
        setError('newUsername', {
          message: 'An error occurred while updating your username',
        });
      }
    } catch (e) {
      setIsLoading(false);
      setError('newUsername', {
        message: 'An error occurred while updating your username',
      });
      captureException(e, {
        extra: {
          newUsername,
        },
      });
      throw e;
    }
  };

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerCenter={
        <View className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full bg-white text-black">
          <Logo variant="default" />
        </View>
      }
      shouldShowFullScreenOnDesktop
    >
      <Text className="mb-5 font-title text-title-xl font-normal text-white">
        Choose a username
      </Text>
      <div className="mb-5 flex h-1 w-[168px] flex-row gap-2">
        <div className="flex h-1 w-40 rounded-full bg-yellow100" />
      </div>
      <Text className="mb-8 text-center font-base text-base-l font-normal text-base200">
        Your username is public and is how you
        <br />
        will appear to artists and members.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <View className="mx-6 my-3 box-content flex flex-col">
          <View className="mx-5 flex flex-row items-center gap-4">
            <input
              type="text"
              {...register('newUsername')}
              placeholder="Username"
              onChange={onChange}
              className="flex-1 border-none bg-transparent font-base text-base-l font-normal text-white focus:border-none focus:outline-none"
              maxLength={20}
            />
            {errorText != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-base700',
              errorText != null && 'bg-destructive300',
            )}
          />
          {errorText != null && (
            <Text className="mt-3 text-center font-base text-base-m font-normal text-destructive300">
              {errorText}
            </Text>
          )}
        </View>
        <div className="bg-dstructive50 mb-5 mt-10 flex w-full justify-center">
          <div className="mx-6">
            <Button
              label={!isLoading ? 'Next' : null}
              type="primary"
              buttonType="submit"
              loading={isLoading}
              iconOnly={isLoading}
              disabled={isSubmitDisabled}
              disabledClassName="opacity-50 cursor-not-allowed"
              event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Username' } }}
            />
          </div>
        </div>
      </form>
    </DefaultLayout>
  );
};
