import { type LegacyRef, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { useLongPress } from 'use-long-press';
import { useSnapshot } from 'valtio';
import {
  faArrowUpArrowDown,
  faFolder,
  faLink,
  faTrash,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPencil } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../../../contexts/MenuContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useToast } from '../../../../contexts/ToastContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import { FolderItemFragmentDoc } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { useSelectVaultContent } from '../../../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../../../hooks/vault/useVaultContentActions';
import {
  FOLDER_TITLE_MAX_LENGTH,
  FOLDER_TITLE_MIN_LENGTH,
  folderToEdit,
  useVaultFolder,
} from '../../../../hooks/vault/useVaultFolder';
import { MoveToContents } from '../../../../screens/vault/MoveToPage';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { pluralizeText } from '../../../../utils/textUtils';
import { TextInputModal } from '../../../bottomsheets/TextInputModal';
import { FolderItem } from '../Folder';
import { type ContentOption, ContentOptions } from './ContentOptions';

export const ArtistFolder = ({
  folderInfo,
  containerRef,
  artistHandle,
  vaultId,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc> & { id: string };

  vaultId: string;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { value: sortingEnabled } = useGate(FEATURE_GATES.VAULT_SORTING);

  const { md2 } = MediaQuery.useContainer();

  const onLongPress = useLongPress(() => {
    if (isSelecting || !md2) return;
    setContextMenuOpen(true);
  })();

  const [selectedFolder, setSelectedFolder] = React.useState(false);
  const {
    isSelecting,
    removeContent: removeSelectedContent,
    addContent: addSelectedContent,
    clearSelection,
  } = useSelectVaultContent();

  const {
    id: folderId,
    title,
    nestedChildrenVaultContentCount,
    linkValue,
    parentVaultContentId,
  } = getFragment(FolderItemFragmentDoc, folderInfo);

  const { loggedInUser } = useAuthContext();

  const editingFolder = useSnapshot(folderToEdit).current;
  const isEditingFolder = editingFolder?.id === folderId;
  const isEditing = isEditingFolder && editingFolder.isEditing;
  const [menuHovered, setMenuHovered] = useState(false);

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: linkValue != null ? `/f/${linkValue}` : `/folder/${folderId}`,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [artistHandle, folderId, linkValue, loggedInUser?.inviteCode]);

  const { copy: onCopyLinkClick } = useCopy({
    text: link,
    successMessage: 'Folder link copied to clipboard!',
  });

  const { removeContents } = useVaultContentActions();
  const { editVaultFolder } = useVaultFolder({ vaultId });

  const onEdit = useStableCallback(async (text: string) => {
    const inputLength = text?.length ?? 0;

    if (inputLength > FOLDER_TITLE_MAX_LENGTH) {
      openToast({
        text: `Folder name cannot exceed ${FOLDER_TITLE_MAX_LENGTH} characters`,
        variant: 'error',
      });
      return;
    }

    if (inputLength < FOLDER_TITLE_MIN_LENGTH) {
      openToast({
        text: `Folder name must be at least ${FOLDER_TITLE_MIN_LENGTH} characters`,
        variant: 'error',
      });
      return;
    }

    if (folderToEdit.current != null) {
      folderToEdit.current.title = text;
    }

    await editVaultFolder();
    closeOverlay();
  });

  const rearrangeButton = {
    title: 'Rearrange',
    icon: faArrowUpArrowDown,
    onClick: () => {
      navigate(
        artistNavigationPath(
          artistHandle,
          `/rearrange${parentVaultContentId != null ? `/${parentVaultContentId}` : ''}`,
        ),
      );
    },
  };

  const deleteButton = {
    title: 'Delete',
    icon: faTrash,
    onClick: () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CONFIRMATION,
        confirmationBottomsheetProps: {
          subText: `This folder contains ${nestedChildrenVaultContentCount} ${pluralizeText({
            count: nestedChildrenVaultContentCount,
            text: 'file',
          })}. Are you sure you want to delete it?`,
          confirmButtonText: 'Delete',
          confirmType: 'delete',
          onConfirm: () => {
            removeContents({
              contentIds: [folderId],
              vaultId,
              onSuccess: () => {
                openToast({
                  text: 'The folder has been deleted',
                  variant: 'success',
                });
              },
            });
          },
        },
      });
    },
  } satisfies ContentOption;

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: onCopyLinkClick,
    },
    {
      title: 'Move to',
      icon: faFolder,
      onClick: () => {
        MoveToContents.contentIds = [folderId];
        navigate(artistNavigationPath(artistHandle, '/move-to'));
      },
    },
  ];

  if (sortingEnabled) {
    buttons.push(rearrangeButton);
  }

  buttons.push(
    {
      title: 'Edit',
      icon: faPencil,
      onClick: () => {
        folderToEdit.current = {
          id: folderId,
          title: title ?? 'Untitled',
          isEditing: true,
        };
        openOverlay(
          <TextInputModal
            title="Rename"
            initialValue={isEditingFolder ? editingFolder?.title : title ?? 'Untitled'}
            confirmText="Ok"
            placeholder="Folder name"
            minLength={FOLDER_TITLE_MIN_LENGTH}
            maxLength={FOLDER_TITLE_MAX_LENGTH}
            onClose={() => {
              if (folderToEdit.current != null) {
                folderToEdit.current.isEditing = false;
              }
            }}
            onInputChange={e => {
              if (folderToEdit.current != null) {
                folderToEdit.current.title = e.target.value;
              }
            }}
            onConfirm={onEdit}
          />,
        );
      },
    },
    deleteButton,
  );

  const onSelectFolder = useStableCallback(() => {
    return setSelectedFolder(prev => {
      if (prev) {
        removeSelectedContent(folderId);
        return false;
      }
      addSelectedContent(folderId);
      return true;
    });
  });

  useEffect(() => {
    if (!isSelecting) {
      setSelectedFolder(false);
    }
  }, [isSelecting]);

  return (
    <ContentOptions
      isOpen={isContextMenuOpen}
      setIsOpen={setContextMenuOpen}
      isSelecting={isSelecting}
      onLongPress={onLongPress}
      onClick={() => {
        if (isSelecting) {
          return onSelectFolder();
        }
        if (!isContextMenuOpen) {
          navigate(
            artistNavigationPath(
              artistHandle,
              linkValue != null ? `/f/${linkValue}` : `/folder/${folderInfo.id}`,
            ),
          );
          clearSelection();
          return;
        }
      }}
      selectedFile={selectedFolder}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={
        <FolderItem
          folderInfo={folderInfo}
          containerRef={containerRef}
          isOwner
          artistHandle={artistHandle}
          editingFolder={editingFolder}
          hasEllipsis={md2}
        />
      }
      disabled={isEditing || isVaultCustomizeOpen}
      disableHover={isVaultCustomizeOpen}
    />
  );
};
