import { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { useIsClamped } from '../../hooks/useIsClamped';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { MusicCampaignPreview } from './MusicCampaignPreview';
import { CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export function MusicCampaignView({
  artistName,
  artistImageUrl,
  isPreview,
  campaignSlug,
}: {
  artistName: string;
  artistImageUrl: string | null | undefined;
  isPreview: boolean;
  campaignSlug: string | null | undefined;
}) {
  const { fields } = useCampaignForm();

  const [isTruncatedDescription, setIsTruncatedDescription] = useState(true);
  const toggleTruncated = useCallback(() => {
    setIsTruncatedDescription(value => !value);
  }, []);
  const textRef = useRef<HTMLDivElement>(null);
  const { isClamped } = useIsClamped(textRef);

  // Creation Flow Preview
  if (isPreview) {
    return (
      <MusicCampaignPreview
        isPresave={fields.campaignType === CampaignType.Presave}
        artistImageUrl={artistImageUrl}
        artistName={artistName}
      />
    );
  }

  return (
    <View className="h-full w-full items-center justify-center md2:flex">
      <Image
        src="https://d3jznoddta6qri.cloudfront.net/public/sample.png"
        alt="Blurred Album Cover"
        className="absolute inset-0 hidden h-full w-full object-cover opacity-75 blur-xl filter md2:block"
      />
      <View className="absolute inset-0 bg-gradient-to-b from-transparent to-black" />

      <View className="flex flex-col items-center md2:h-full md2:w-2/3 md2:min-w-[825px] md2:max-w-[934px] md2:flex-row md2:gap-20">
        <View className="relative mx-auto flex aspect-square w-full flex-col items-center justify-center overflow-hidden">
          <Image
            src="https://d3jznoddta6qri.cloudfront.net/public/sample.png"
            alt="Blurred Album Cover"
            className="absolute inset-0 h-full w-full object-cover opacity-75 blur-xl filter md2:hidden"
          />

          <View className="absolute inset-0 bg-gradient-to-b from-transparent via-black/80 to-black md2:hidden" />

          <Image
            src="https://d3jznoddta6qri.cloudfront.net/public/sample.png"
            alt="Album Cover"
            className="z-10 relative aspect-square w-2/3 rounded-xl object-cover md2:w-full"
          />
        </View>

        <View className="flex w-full -translate-y-7 flex-col items-center md2:translate-y-0 md2:items-start">
          <Text className="text-center text-title-xl font-semibold text-white md2:text-left md2:text-title-xxl">
            {campaignSlug || 'Untitled'}
          </Text>

          <div className="mt-2 flex items-center justify-center space-x-2">
            <ArtistProfileImage
              className="h-6 w-6 md2:h-8 md2:w-8"
              profileImageUrl={artistImageUrl}
              withVaultTheme
            />
            <Text className="text-base-m text-white md2:text-base-l md2:font-medium">
              {artistName}
            </Text>
          </div>

          <View
            className="mt-8 flex w-3/4 cursor-pointer flex-row items-center justify-center text-center text-base400 md2:w-full md2:justify-start md2:text-left"
            onClick={toggleTruncated}
          >
            <View
              className={clsx(
                '!text-base-m font-normal md2:!text-base-l',
                isTruncatedDescription ? 'line-clamp-2' : '',
              )}
              containerRef={textRef}
            >
              BabyJake is for the people. BabyJake is for making it sexy. BabyJake is for YOU. VOTE
              BABYJAKE 024.
            </View>
            {isTruncatedDescription && isClamped && (
              <span className="self-end !text-base-m font-normal text-base600">more</span>
            )}
          </View>

          <View className="mt-8 flex w-[80vw] flex-col gap-8 rounded-xl bg-white/10 px-6 py-8 md2:w-full">
            <Text>Test</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export const MusicCampaignSkeleton = () => {
  return (
    <View className="h-full w-full items-center justify-center overflow-hidden md2:flex">
      <View className="flex flex-col items-center md2:h-full md2:w-2/3 md2:min-w-[825px] md2:max-w-[934px] md2:flex-row md2:gap-20">
        <LoadingSkeleton className="relative mx-auto flex aspect-square w-full flex-col items-center justify-center overflow-hidden rounded-xl" />

        <View className="mt-8 flex w-full flex-col items-center md2:translate-y-0 md2:items-start">
          <LoadingSkeleton className="h-[30px] w-[120px]" />

          <View className="mt-2 flex items-center justify-center space-x-2">
            <LoadingSkeleton className="aspect-square h-[24px] rounded-full" />
            <LoadingSkeleton className="h-[24px] w-[100px]" />
          </View>

          <View className="mt-8 flex w-3/4 flex-row items-center justify-center text-center text-base400 md2:w-full md2:justify-start md2:text-left">
            <LoadingSkeleton className="h-[30px] w-[250px]" />
          </View>

          <LoadingSkeleton className="mt-8 flex h-[130px] w-[80vw] flex-col gap-8 rounded-xl px-6 py-8 md2:w-full" />
        </View>
      </View>
    </View>
  );
};
