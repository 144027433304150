import { useMemo } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { faCalendar } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { DateInput } from '../../screens/vault/announcement/Create';
import { CampaignHeaderSection } from '../../screens/vault/campaign/CreateCampaignPage';
import { formatSelectedDate, getZonedDate } from '../announcement/helpers';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { CampaignImageUpload } from './CampaignImageUpload';
import { CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const SetupView = ({ artistId }: { artistId: string }) => {
  const { setField, fields, errors, validateField } = useCampaignForm();

  const currDate = useMemo(() => new Date(), []);

  const timeZone = useMemo(() => {
    return format(getZonedDate(currDate), 'z');
  }, [currDate]);

  const formatDate = formatSelectedDate(fields.releaseDate);

  return (
    <View className="flex h-full w-full flex-col">
      <CampaignHeaderSection
        icon={faCalendar}
        title="Event details"
        description="Enter the key details for your song"
      />

      <CampaignImageUpload artistId={artistId} />

      <Text className="mt-8 font-title !text-title-s font-medium text-vault_text">Title</Text>
      <input
        className={clsx(
          'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
          errors.title ? 'border-destructive300' : 'border-vault_text/10 focus:border-vault_text',
        )}
        placeholder="Add a title..."
        value={fields.title}
        onChange={e => {
          setField('title', e.target.value);
          validateField('title');
        }}
      />
      {errors.title && <Text className="mt-2 text-base-s text-destructive300">{errors.title}</Text>}

      <Text className="mt-4 font-title !text-title-s font-medium text-vault_text">Artist</Text>
      <input
        className={clsx(
          'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
          errors.artist ? 'border-destructive300' : 'border-vault_text/10 focus:border-vault_text',
        )}
        placeholder="Artist name"
        value={fields.artist}
        onChange={e => {
          setField('artist', e.target.value);
          validateField('artist');
        }}
      />
      {errors.artist && (
        <Text className="mt-2 text-base-s text-destructive300">{errors.artist}</Text>
      )}

      {/* Presave Specific */}
      {fields.campaignType === CampaignType.Presave && (
        <>
          <Text className="mt-4 font-title !text-title-s font-medium text-vault_text">
            Description
          </Text>
          <textarea
            value={fields.description}
            name="description"
            aria-multiline="true"
            placeholder="Write your description here..."
            className="no-scrollbar mb-4 mt-2 min-h-[120px] resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50 focus:border-vault_text"
            onChange={e => {
              setField('description', e.target.value);
              validateField('description');
            }}
            onBlur={() => {
              validateField('description');
            }}
          />

          <Text className="font-title !text-title-s font-medium text-vault_text">Release date</Text>
          <DatePicker
            value={formatDate}
            selected={fields.releaseDate}
            onChange={date => {
              if (!date) {
                setField('releaseDate', currDate);
                validateField('releaseDate');
                return;
              }
              setField('releaseDate', date);
              validateField('releaseDate');
            }}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            className={clsx(
              'mt-2 w-full rounded-md border border-solid p-4 font-base !text-base-l text-vault_text outline-none',
              errors.releaseDate
                ? 'border-destructive300'
                : 'border-vault_text/10 focus:border-vault_text ',
            )}
            placeholderText="Now"
            customInput={<DateInput isThemeEnabled />}
            minDate={currDate}
            timeIntervals={1}
          />
          {errors.releaseDate ? (
            <Text className="mt-2 text-base-s text-destructive300">{errors.releaseDate}</Text>
          ) : (
            <Text className="mb-4 h-6 pt-1 font-base !text-base-s tabular-nums text-vault_text/50">
              {timeZone}
            </Text>
          )}
        </>
      )}
    </View>
  );
};
