import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { faArrowDown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';

import type {
  MessageChannelUpdatesDocument,
  MessageSource,
  PinnedMessageChannelQuery,
} from '../../graphql/generated';
import {
  FeatureTypename,
  type FragmentType,
  getFragment,
  MessageBubbleFragmentDoc,
  MessageChannelViewFragmentDoc,
  TierTypename,
} from '../../graphql/generated';
import type { OnData } from '../../graphql/wsClient';
import { useDrag } from '../../hooks/useDrag';
import { useMessageChannelUpdatesSubscription } from '../../hooks/useMessageChannelUpdatesSubscription';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import { compareDates, dateToTime, isSamePeriod } from '../../utils/dateUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import {
  MessageBubble,
  MessageBubbleInteractions,
  SkeletonMessageBubble,
} from '../message/MessageBubble';
import { MessageCommentBubble } from '../message/MessageCommentBubble';
import { PinnedMessage } from '../message/PinnedMessage';

gql(/* GraphQL */ `
  fragment messageChannelView on MessageChannel {
    id
    vault {
      id
      artist: artistProfile {
        id
        name
        linkValue
        profileImage {
          id
          url
        }
      }
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
    messages: messagesPagination(after: $after, first: $first, includeTrackComments: false) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          source
          id
          createdAt
          ...messageBubble
        }
        cursor
      }
    }
  }
`);

type Props = {
  messageChannel: FragmentType<MessageChannelViewFragmentDoc>;
  messages: (FragmentType<MessageBubbleFragmentDoc> & {
    id: string;
    createdAt: string;
    source: MessageSource;
  })[];
  loadMoreNextPage: () => void;
  onMessageChannelUpdate: OnData<MessageChannelUpdatesDocument>;
  isOwner?: boolean;
  pinnedMessages:
    | NonNullable<PinnedMessageChannelQuery['messageChannelByArtistHandle']>['pinnedMessages']
    | undefined;
  artistLinkValue: string;
  hasNextPage?: boolean;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
  activeSubscriptionFeatures: TierFeatures | null;
  vaultId: string | undefined;
};

const defaultContainerSpacing = -65;

const defaultContainerMarginRight = `${defaultContainerSpacing}px`;

export const MessageChannelView: FC<Props> = ({
  messageChannel,
  loadMoreNextPage,
  onMessageChannelUpdate,
  messages,
  isOwner = false,
  pinnedMessages,
  hasNextPage,
  virtuosoRef,
  artistLinkValue,
  activeSubscriptionFeatures,
  vaultId,
}) => {
  const messageChannelFrag = getFragment(MessageChannelViewFragmentDoc, messageChannel);
  const isScrolling = useRef(false);

  // Feature Access
  const areSubscriptionTierBadgesVisible = messageChannelFrag.vault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatWrite);
  const hasChatWriteAccess = activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true;
  const hasTrackCommentsReadAccess =
    activeSubscriptionFeatures?.enabledFeatures.TrackCommentsRead === true;

  const [hasSeenNewMessages, setHasSeenNewMessages] = useState(true);
  const {
    ref: inViewRef,
    inView: isBottomInView,
    entry,
  } = useInView({
    delay: 100,
  });

  useMessageChannelUpdatesSubscription({
    messageChannelId: messageChannelFrag.id,
    onSubscriptionData: data => {
      if (
        data.data.messageChannelUpdates.__typename === 'SubscriptionMessageChannelUpdatesSuccess' &&
        data.data.messageChannelUpdates.data.__typename === 'CreateMessageSubscription' &&
        !isBottomInView
      ) {
        setHasSeenNewMessages(false);
      }

      onMessageChannelUpdate(data);
    },
  });

  useEffect(() => {
    if (isBottomInView) {
      setHasSeenNewMessages(true);
    }
  }, [isBottomInView]);

  const ref = useRef<HTMLDivElement>(null);

  const scrollerRef = useRef<HTMLElement | Window | null>(null);

  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);

  const [containerMarginRight, setContainerMarginRight] = useState(defaultContainerMarginRight);

  const resetMarginRight = useStableCallback(() => {
    setContainerMarginRight(defaultContainerMarginRight);
    MessageBubbleInteractions.enableLongPress = true;
  });

  useEffect(() => {
    let maxLeft = 0;
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      const currentTarget = e.currentTarget as HTMLElement;

      if (currentTarget) {
        currentTarget.scrollTop -= e.deltaY;

        if (e.deltaX > 0 && e.deltaY === 0) {
          MessageBubbleInteractions.enableLongPress = false;

          maxLeft += e.deltaX;
          setContainerMarginRight(`${Math.min(defaultContainerSpacing + maxLeft, 0)}px`);
        } else {
          maxLeft = 0;
          resetMarginRight();
        }
      }
    };

    const ref = scrollerRef.current as HTMLElement;

    ref.addEventListener('wheel', handleScroll, {
      passive: false,
    });

    return () => {
      ref.removeEventListener('wheel', handleScroll);
    };
  }, [resetMarginRight]);

  const { resetDragging } = useDrag(ref, {
    onDragLeft({ amount }) {
      if (isScrolling.current) return;

      MessageBubbleInteractions.enableLongPress = false;
      setContainerMarginRight(`${Math.min(defaultContainerSpacing + amount, 0)}px`);
    },
    onPointerUp: resetMarginRight,
    onDragRight: resetMarginRight,
    directionThreshold: 20,
  });

  const now = useMemo(() => new Date(), []);

  const firstPin = pinnedMessages?.[0];

  const LoadingFooter = React.useCallback(
    () =>
      hasNextPage ? (
        <SkeletonMessageBubble isAuthor={false} className="rotate-180 scale-x-[-1] pt-3" />
      ) : (
        <View className="pt-3" />
      ),
    [hasNextPage],
  );

  const Header = React.useCallback(() => <div ref={inViewRef} />, [inViewRef]);

  const messageContent = (
    i: number,
    message: FragmentType<MessageBubbleFragmentDoc> & {
      id: string;
      createdAt: string;
      source: MessageSource;
    },
  ) => {
    const { asArtist } = getFragment(MessageBubbleFragmentDoc, message);
    return (
      <MessageBubbleContainer
        key={message.id}
        i={i}
        areSubscriptionTierBadgesVisible={!!areSubscriptionTierBadgesVisible}
        hasChatWriteAccess={hasChatWriteAccess}
        hasTrackCommentsReadAccess={hasTrackCommentsReadAccess}
        message={message}
        now={now}
        messages={messages}
        isVaultArtist={messageChannelFrag.vault?.artist?.id === asArtist?.id}
        isOwner={isOwner}
        artistId={messageChannelFrag.vault?.artist?.id}
        artistName={messageChannelFrag.vault?.artist?.name}
        artistProfileImageUrl={messageChannelFrag.vault?.artist?.profileImage?.url}
        containerMarginRight={containerMarginRight}
        resetDragging={resetDragging}
        resetMarginRight={resetMarginRight}
        hasNextPage={hasNextPage}
        vaultId={vaultId}
      />
    );
  };

  const scrollToBottom = useCallback(() => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
  }, [virtuosoRef]);

  return (
    <View
      className="relative box-border flex w-full flex-1 flex-col items-center overflow-x-clip overscroll-none px-[10px] text-vault_text"
      containerRef={ref}
    >
      {firstPin != null && pinnedMessages && (
        <PinnedMessage
          message={firstPin}
          artistHandle={artistLinkValue}
          pinnedMessageCount={pinnedMessages.length}
        />
      )}

      {entry && !isBottomInView ? (
        <Button
          label={null}
          labelComponent={
            !hasSeenNewMessages ? (
              <Text className="!text-base-m font-medium text-vault_text">New messages</Text>
            ) : null
          }
          leadingIcon={faArrowDown}
          iconOnly={hasSeenNewMessages}
          className="absolute bottom-[10px] z-stickyHeader rounded-full bg-vault_text p-2 text-vault_text_opposite"
          leadingIconClassName="h-[16px] w-[16px]"
          onClick={scrollToBottom}
        />
      ) : null}

      <Virtuoso
        data={messages}
        itemContent={messageContent}
        components={{ Footer: LoadingFooter, Header }}
        alignToBottom
        className="no-scrollbar w-full rotate-180 scale-x-[-1] overflow-x-hidden overscroll-none"
        ref={virtuosoRef}
        endReached={loadMoreNextPage}
        overscan={{ reverse: 100, main: 1000 }}
        scrollerRef={handleScrollerRef}
        isScrolling={scrolling => (isScrolling.current = scrolling)}
      />
    </View>
  );
};

const MessageBubbleContainer = ({
  i,
  message,
  messages,
  areSubscriptionTierBadgesVisible,
  hasChatWriteAccess,
  hasTrackCommentsReadAccess,
  now,
  artistProfileImageUrl,
  isOwner,
  resetDragging,
  resetMarginRight,
  artistName,
  artistId,
  containerMarginRight,
  isVaultArtist,
  hasNextPage,
  vaultId,
}: {
  i: number;
  message: FragmentType<MessageBubbleFragmentDoc> & {
    id: string;
    createdAt: string;
    source: MessageSource;
  };
  areSubscriptionTierBadgesVisible: boolean;
  hasChatWriteAccess: boolean;
  hasTrackCommentsReadAccess: boolean;
  messages: (FragmentType<MessageBubbleFragmentDoc> & { id: string; createdAt: string })[];
  now: Date;
  artistName: string | undefined;
  artistId: string | undefined;
  artistProfileImageUrl: string | undefined;
  isOwner: boolean;
  containerMarginRight: string;
  resetMarginRight: () => void;
  resetDragging: () => void;
  isVaultArtist: boolean;
  hasNextPage?: boolean;
  vaultId: string | undefined;
}) => {
  const { id, createdAt, source } = message;

  const nextMessage = messages[i + 1];

  const nextMessageIsInDifferentGroup =
    !!nextMessage && !isSamePeriod(new Date(createdAt), new Date(nextMessage.createdAt), 2);

  const dateMemo = useMemo(
    () =>
      nextMessageIsInDifferentGroup || (!nextMessage && !hasNextPage) ? (
        <p className="pb-2 text-center !text-base-s text-vault_text/50">
          <b>{compareDates(new Date(createdAt), now)}</b> {dateToTime(createdAt)}
        </p>
      ) : null,
    [createdAt, hasNextPage, nextMessage, nextMessageIsInDifferentGroup, now],
  );

  return (
    <View key={id} className="rotate-180 scale-x-[-1]">
      {dateMemo}
      {source === 'VAULT_CHAT' ? (
        <MessageBubble
          message={message}
          key={id}
          isOwner={isOwner}
          containerMarginRight={containerMarginRight}
          onLongPress={() => {
            resetMarginRight();
            resetDragging();
          }}
          onReplyPress={() => {
            resetMarginRight();
            resetDragging();
          }}
          artistName={artistName}
          vaultArtistId={artistId}
          isVaultArtist={isVaultArtist}
          artistProfileImageUrl={artistProfileImageUrl}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          hasChatWriteAccess={hasChatWriteAccess}
          vaultId={vaultId}
        />
      ) : (
        <MessageCommentBubble
          hasTrackCommentsReadAccess={hasTrackCommentsReadAccess}
          message={message}
          isVaultArtist={isVaultArtist}
          containerMarginRight={containerMarginRight}
        />
      )}
    </View>
  );
};

export const EmptyMessageChannel = ({
  sendMessage,
}: {
  sendMessage: (content: string) => Promise<void> | void;
}) => {
  return (
    <View className="z-above4 box-border flex h-full w-full flex-1 flex-col items-center justify-center px-5">
      <Text className="mb-2 font-title !text-title-s font-medium text-vault_text">
        It's a bit quiet in here...
      </Text>
      <Text className="mb-5 text-center font-base !text-base-m font-medium text-vault_text/50">
        Be the first to send a message or tap the wave below
      </Text>
      <Button
        label="👋"
        className="h-[100px] w-[100px] items-center justify-center rounded-full border-4 border-solid border-vault_text/20 bg-vault_text/10 text-[50px]"
        onClick={() => sendMessage('👋')}
      />
    </View>
  );
};

export const SkeletonMessageChannel = () => {
  return (
    <View className="mx-[10px] box-border flex w-full flex-col items-center bg-vault_background px-5 pt-2 text-vault_text md2:bg-transparent md2:pt-4">
      <View className="flex w-full flex-1 flex-col-reverse gap-4 px-[10px] scrollbar-none">
        <SkeletonMessageBubble isAuthor={false} />
        <SkeletonMessageBubble isAuthor />
        <SkeletonMessageBubble isAuthor={false} />
        <SkeletonMessageBubble isAuthor />
        <SkeletonMessageBubble isAuthor={false} />
        <SkeletonMessageBubble isAuthor />
        <SkeletonMessageBubble isAuthor={false} />
        <SkeletonMessageBubble isAuthor />
        <SkeletonMessageBubble isAuthor={false} />
        <SkeletonMessageBubble isAuthor />
        <SkeletonMessageBubble isAuthor={false} />
      </View>
    </View>
  );
};
