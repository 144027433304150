import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'date-fns';
import { faFloppyDisk } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { EventItemFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { Image } from '../common/Image';

gql(/* GraphQL */ `
  fragment EventItem on ReleaseCampaign {
    id
    __typename
    title
    releaseDate
    linkValue
    status
    coverImage {
      id
      url
    }
  }
`);

export const EventItem = ({ eventData }: { eventData: FragmentType<EventItemFragmentDoc> }) => {
  const { title, coverImage, releaseDate, linkValue } = getFragment(
    EventItemFragmentDoc,
    eventData,
  );

  const releaseDateFormatted = !!releaseDate
    ? `${formatDate(releaseDate, 'cccccc, MMM d · p')} `
    : null;
  return (
    <View className="flex items-center justify-between gap-3">
      <View className="flex h-full justify-between gap-4">
        {!!coverImage?.url ? (
          <Image
            src={coverImage.url}
            alt={title}
            className="aspect-square w-16 flex-shrink-0 rounded-md bg-neutral500"
          />
        ) : (
          <View className="aspect-square w-16 flex-shrink-0 rounded-md bg-neutral500" />
        )}
        <View className="flex flex-col justify-between py-0.5">
          <Text className="max-w-56 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-title text-[18px]/[20px] font-medium">
            {title}
          </Text>
          <View className="flex flex-col">
            <span className="flex items-center gap-1 text-[14px]/[16px] font-normal">
              <FontAwesomeIcon icon={faFloppyDisk} className=" text-vault_text/60" />
              <Text className="font-base text-vault_text/60">Pre-save event</Text>
            </span>
            {!!releaseDateFormatted && (
              <Text className="text-[12px] text-vault_text/50">{releaseDateFormatted}</Text>
            )}
          </View>
        </View>
      </View>

      <Button
        href={linkValue}
        type="primary"
        label="Claim receipt"
        className="hidden flex-shrink-0 py-3.5 lg:block"
      />
    </View>
  );
};

export const EventItemSkeleton = () => (
  <View className="flex w-full items-center justify-between gap-3">
    <View className="flex h-full w-full justify-between gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-between py-0.5">
        <View className="h-5 w-[50%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[30%] animate-pulse rounded-sm bg-vault_text/10" />
          <View className="h-2 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>

    <View className="h-12 w-[150px] flex-shrink-0 animate-pulse rounded-full bg-vault_text/10" />
  </View>
);
