import { RepeatMode } from '../../audio/AudioMeta';
import { useSpotifyContext } from '../../contexts/SpotifyContext';
import { useWindow } from '../../hooks/useWindow';
import { BottomAudioPlayer, DesktopBottomAudioPlayer } from '../audioPlayer/BottomAudioPlayer';

export function SpotifyBottomAudioPlayer({
  withBottomNavigator,
  withVaultTheme,
}: {
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
}) {
  const { isDesktop } = useWindow();

  const { isPlaybackReady, player, playbackState, device } = useSpotifyContext();

  if (
    !isPlaybackReady ||
    player == null ||
    device == null ||
    device.status !== 'ready' ||
    playbackState == null
  ) {
    return null;
  }

  const { duration, paused, repeat_mode, loading, position } = playbackState;

  //TODO fetch information about the current track

  if (isDesktop) {
    return (
      <DesktopBottomAudioPlayer
        withVaultTheme={withVaultTheme}
        type="spotify"
        artistName="Spotify"
        disableNextPrev
        isNextTrackDisabled
        title="Spotify"
        loading={loading}
        duration={duration / 1000}
        playing={!paused}
        repeatMode={
          repeat_mode === 0
            ? RepeatMode.NO_REPEAT
            : repeat_mode === 1
              ? RepeatMode.REPEAT_ONE
              : RepeatMode.REPEAT_ALL
        }
        shuffleEnabled={false}
        loadingActiveTrack={false}
        togglePlayPause={() => {
          player.activateElement().then(() => {
            player.togglePlay();
          });
        }}
        position={position / 1000}
        seek={position => {
          player.seek(position * 1000);
        }}
      />
    );
  }

  //TODO fetch information about the current track
  return (
    <BottomAudioPlayer
      withBottomNavigator={withBottomNavigator}
      withVaultTheme={withVaultTheme}
      type="spotify"
      subtitle="Spotify"
      title="Spotify"
      duration={duration / 1000}
      playing={!paused}
      seek={position => {
        player.seek(position * 1000);
      }}
      leftImageUrl={null}
      togglePlayPause={() => {
        player.activateElement().then(() => {
          player.togglePlay();
        });
      }}
      position={position / 1000}
      shouldOpenFullscreen={false}
    />
  );
}
