import { useEffect } from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { CampaignScanLinkView } from '../../../components/campaign/CampaignScanLinkView';
import { LinksView } from '../../../components/campaign/LinksView';
import { MusicCampaignView } from '../../../components/campaign/MusicCampaignView';
import { ReleaseView } from '../../../components/campaign/ReleaseView';
import { SetupView } from '../../../components/campaign/SetupView';
import { CampaignLinksType, CampaignSteps } from '../../../components/campaign/schema';
import { useCampaignForm } from '../../../components/campaign/useCampaignForm';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useQuery } from '../../../graphql/client';
import { ArtistByHandleDocument } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export function CreateCampaignPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { value: presaveEnabled, isLoading: isLoadingGate } = useGate(FEATURE_GATES.PRESAVES);

  const navigate = useNavigate();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();

  useVaultTheme();

  const {
    fields,
    isSetupComplete,
    isLinksComplete,
    isReleaseComplete,
    enableSubmit,
    releaseCampaignCreationLoading,
    clearErrors,
    clearFields,
    onSubmit,
    determineNextStep,
    determinePrevStep,
  } = useCampaignForm();

  const step = searchParams.get('step') || CampaignSteps.Scan;

  const { setField } = useCampaignForm();
  const { isLoading, data, isError } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const artistId = data?.data?.artistLink?.artist?.id;
  const artistName = data?.data?.artistLink?.artist?.name;

  const onBackClick = useStableCallback(() => {
    const prevStep = determinePrevStep(step as CampaignSteps);
    if (prevStep) {
      setSearchParams({ step: prevStep });
    } else {
      openBottomsheet({
        type: 'EXIT_FLOW',
        exitFlowBottomsheetProps: {
          onConfirm: () => {
            navigate(artistNavigationPath(artistHandle, '/'));
            clearFields();
            clearErrors();
          },
        },
      });
    }
  });

  const handleNextClick = () => {
    const currentStep = step as CampaignSteps;
    const nextStep = determineNextStep(currentStep);
    if (nextStep && nextStep !== step) {
      setSearchParams({ step: nextStep });
    } else if (currentStep === CampaignSteps.Preview && enableSubmit) {
      onSubmit();
    }
  };

  useEffect(() => {
    if (artistName) setField('artist', artistName);
  }, [artistName, setField]);

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }
  }, [artistHandle, navigate]);

  useEffect(() => {
    if (!fields.campaignType) {
      navigate(artistNavigationPath(artistHandle, '/'));
      return;
    }
  }, [artistHandle, fields, navigate, setSearchParams]);

  useEffect(() => {
    if (isLoadingGate) return;

    if (
      (artistId && loggedInUser?.artist?.id && artistId !== loggedInUser.artist.id) ||
      loginStatus !== LoginStatus.LOGGED_IN ||
      !presaveEnabled
    ) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [
    artistHandle,
    artistId,
    isLoadingGate,
    loggedInUser?.artist?.id,
    loginStatus,
    navigate,
    presaveEnabled,
  ]);

  if (isLoading || data == null) {
    return <CampaignSkeleton onBackClick={onBackClick} />;
  }

  if (!artistId || isError) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const buttonText = () => {
    switch (step) {
      case CampaignSteps.Setup:
      case CampaignSteps.PresavePrereleaseLinks:
      case CampaignSteps.PresaveReleaseLinks:
      case CampaignSteps.StreamReleaseLinks:
        return 'Next';
      case CampaignSteps.Release:
        return 'Preview';
      case CampaignSteps.Preview:
        return 'Launch event';
      default:
        return null;
    }
  };

  const isButtonDisabled = () => {
    if (step === CampaignSteps.Setup) {
      return !isSetupComplete;
    }
    if (
      step === CampaignSteps.PresavePrereleaseLinks ||
      step === CampaignSteps.StreamReleaseLinks
    ) {
      return !isLinksComplete;
    }
    if (step === CampaignSteps.Release) {
      return !isReleaseComplete;
    }
    return !enableSubmit;
  };

  const content = () => {
    switch (step) {
      case CampaignSteps.Scan:
        return <CampaignScanLinkView />;
      case CampaignSteps.Setup:
        return <SetupView artistId={artistId} />;
      case CampaignSteps.PresavePrereleaseLinks:
        return <LinksView type={CampaignLinksType.PresavePrereleaseLinks} />;
      case CampaignSteps.PresaveReleaseLinks:
        return <LinksView type={CampaignLinksType.PresaveReleaseLinks} />;
      case CampaignSteps.StreamReleaseLinks:
        return <LinksView type={CampaignLinksType.StreamReleaseLinks} />;
      case CampaignSteps.Release:
        return <ReleaseView />;
      case CampaignSteps.Preview:
        return (
          <MusicCampaignView
            isPreview
            artistName={data?.data?.artistLink?.artist?.name || artistHandle || ''}
            artistImageUrl={data?.data?.artistLink?.artist?.profileImage?.url}
            campaignSlug={null}
          />
        );
      default:
        return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  };

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={step !== CampaignSteps.Preview ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <>
          {step === CampaignSteps.Preview && (
            <Text
              className={twMerge(
                'font-title !text-title-m font-medium',
                step !== CampaignSteps.Preview ? 'text-vault_text' : 'text-white',
              )}
            >
              Event preview
            </Text>
          )}
        </>
      }
      shouldSkipMargin
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="grow-0"
      contentClassName={twMerge(
        'flex-1',
        step !== CampaignSteps.Preview ? 'md2:bg-vault_text/3' : 'bg-black',
      )}
      extend
    >
      <View className="flex w-full flex-1 flex-col md2:max-w-[600px] md2:pt-4">
        <View className="flex w-full flex-1 flex-col gap-6 md2:gap-7">
          <View className="flex w-full flex-1 flex-col">
            <View className="mx-4">{content()}</View>

            {step !== CampaignSteps.Scan && buttonText() && (
              <View
                className={twMerge(
                  'mt-auto flex justify-center px-4 py-6',
                  step === CampaignSteps.Preview &&
                    'border-x-0 border-b-0 border-t-1.5 border-solid border-vault_text/10 bg-vault_background',
                )}
              >
                <Button
                  type="primary"
                  label={buttonText()}
                  className="!text-base-3 w-full self-center bg-vault_accent text-vault_accent_text"
                  loading={releaseCampaignCreationLoading}
                  disabled={isButtonDisabled()}
                  disabledClassName="opacity-50 cursor-not-allowed"
                  onClick={handleNextClick}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </DefaultLayout>
  );
}

const CampaignSkeleton = ({ onBackClick }: { onBackClick: () => void }) => {
  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">
          Pre-save campaign
        </Text>
      }
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      contentClassName="md2:bg-vault_text/3"
      stretch
    >
      <View className="flex h-full w-full flex-col justify-between py-6 md2:max-w-[600px]">
        <View className="flex h-full w-full flex-col gap-6 md2:gap-7">
          <View className="flex h-full w-full flex-col">
            <LoadingSkeleton className="aspect-square w-full rounded-lg" />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />
          </View>
        </View>
      </View>
    </DefaultLayout>
  );
};

export const CampaignHeaderSection = ({
  icon,
  subIcon,
  title,
  description,
}: {
  icon: IconDefinition;
  subIcon?: IconDefinition | undefined;
  title: string;
  description: string;
}) => {
  return (
    <View className="mb-8 flex flex-col items-center justify-center px-8 text-center">
      <View className="flex flex-row items-end">
        <FontAwesomeIcon icon={icon} fontSize={36} />
        {subIcon && <FontAwesomeIcon icon={subIcon} fontSize={16} />}
      </View>
      <Text className="mt-5 text-title-l font-medium">{title}</Text>
      <Text className="mt-2 max-w-[350px] text-base-m text-vault_text/50">{description}</Text>
    </View>
  );
};
