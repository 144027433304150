import type { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { useEffect } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { ArtistProfileImage } from '../../components/artist/ArtistProfileImage';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { Item, Select } from '../../components/forms/Select';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { Logo } from '../../components/svg/Logo';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { useAttachPayee } from '../../components/views/hooks/useAttachPayee';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistProfileImageByHandleDocument } from '../../graphql/generated';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { wrapSendCode } from '../../utils/privy';
import { constructQueryParams } from '../../utils/stringUtils';
import { ONE_MINUTE_IN_MS, SignInStore } from './store';

gql(/* GraphQL */ `
  query ArtistProfileImageByHandle($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        profileImage {
          id
          url
        }
      }
    }
  }
`);

export const SignInPage: FC = () => {
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const artistHandle = searchParams.get('artistHandle');
  const payeeSecretId = searchParams.get('psid');
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const trackId = searchParams.get('trackId');
  const source = searchParams.get('source');
  const invite = searchParams.get('invite');
  const linkSpotify = searchParams.get('linkSpotify');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const fromJoin = source === 'join';

  const { isLoading: loadingArtistProfilePic, data: artistData } = useQuery(
    ArtistProfileImageByHandleDocument,
    {
      staleTime: 0,
      variables: !!artistHandle && {
        link: artistHandle.toLowerCase(),
      },
      filterQueryKey: {
        artistHandle,
      },
      keepPreviousData: true,
      enabled: artistHandle != null,
    },
  );

  const artistProfileImage = artistData?.data.artistLink?.artist.profileImage?.url;

  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
  } = useSignIn();

  const { loginStatus, loggedInUser } = useAuthContext();

  const {
    sendCode,
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const { attachPayeeIfPresent } = useAttachPayee();

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme={false} />;
  }

  const navigationPath = ({
    artistHandle,
    loggedInUser,
  }: {
    artistHandle?: string | null;
    loggedInUser?: {
      highestSubscriptionLevel?: string | null;
    } | null;
  }) => {
    if (artistHandle != null) {
      return artistNavigationPath(artistHandle, '/');
    }
    if (loggedInUser?.highestSubscriptionLevel != null) {
      return ROUTES.VAULTS;
    }
    return ROUTES.VAULTS;
  };

  if (loginStatus === LoginStatus.LOGGED_IN) {
    const navigateTo = navigationPath({ artistHandle, loggedInUser });
    // Payee collaborator onboarding flow
    attachPayeeIfPresent();

    return <Navigate to={navigateTo} />;
  }

  if (status === 'awaiting-code-input' && lastActivePhoneNumber) {
    const queryParams = constructQueryParams({
      artistHandle,
      openBottomSheet: bottomSheetType,
      redirect,
      code,
      psid: payeeSecretId,
      trackId,
      invite,
      linkSpotify,
      c: smsCampaignResponseShortcode,
    });
    return <Navigate to={`${ROUTES.VERIFY}?${queryParams}`} />;
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' }, pathname });

    if (!validPhoneNumber) {
      setErrorText('This phone number cannot be used for verification');
      return;
    }

    try {
      setErrorText(null);
      await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

      const codeRenabled = Date.now() + ONE_MINUTE_IN_MS;

      SignInStore.produceExistingState(
        draft => {
          draft.lastActivePhoneNumber = validPhoneNumber;
          draft.codeRenabled[validPhoneNumber] = codeRenabled;
        },
        {
          codeRenabled: {
            [validPhoneNumber]: codeRenabled,
          },
          lastActivePhoneNumber: validPhoneNumber,
        },
      );
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          errorText: 'We encountered an error sending your verification code',
          phoneNumber: validPhoneNumber,
        },
      });
      setErrorText('We encountered an error sending your verification code');
      return;
    }
  };

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      shouldShowFullScreenOnDesktop
      headerLeft={<BackButton />}
      headerCenter={
        loadingArtistProfilePic ? (
          <LoadingSkeleton className="h-10 w-10 rounded-full bg-neutral700" />
        ) : !!artistProfileImage ? (
          <ArtistProfileImage
            className="aspect-square h-10 w-10 rounded-full object-cover object-center"
            profileImageUrl={artistProfileImage}
          />
        ) : (
          <View className="flex h-[40px] w-[40px] flex-col items-center justify-center">
            <Logo variant="default" />
          </View>
        )
      }
    >
      <Text className="mb-[28px] font-title text-title-xl font-normal text-white">
        {fromJoin ? 'Join Vault' : 'Sign In'}
      </Text>
      <form onSubmit={onNextClick} className="w-full">
        <View className="mx-6 my-3 box-content flex flex-col">
          <View className="box-border grid grid-flow-col grid-cols-4 flex-row items-center gap-4 overflow-hidden">
            <View className="col-span-1 flex justify-end">
              <Select
                value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                onValueChange={val => setCountryCode(val)}
                itemProps={{
                  className: 'bg-transparent text-white hover:bg-transparent focus:bg-transparent',
                }}
                className="w-[5em] bg-transparent p-0 font-base text-base-l font-normal text-white hover:bg-transparent"
                contentClassName="bg-[#1f1f1f] w-[5em]"
                shouldHideIcon
                onOpenChange={setIsOpen}
                disabled={status === 'sending-code'}
              >
                {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                  <Item
                    key={code}
                    value={code}
                    className="box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base text-base-l font-normal text-white hover:bg-transparent focus:bg-transparent"
                    dropDownClassName="box-border bg-[#1f1f1f] hover:bg-[#1f1f1f] focus:bg-[#1f1f1f] font-base text-base-l font-normal rounded-sm justify-center pr-1 overflow-x-clip"
                  >
                    <Text className="mx-2 text-[24px]">{flag}</Text>
                    <Text className="pr-2 text-[12px] md2:pr-0">{dial_code}</Text>
                  </Item>
                ))}
              </Select>
            </View>
            <input
              type="tel"
              value={phone}
              placeholder="Enter phone number"
              onChange={onChange}
              disabled={status === 'sending-code'}
              autoFocus
              className="col-span-3 col-start-2 ml-2 flex-1 border-none bg-transparent font-base text-base-l font-normal text-white focus:w-[unset] focus:border-none focus:outline-none"
            />
            {errorText != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-base700',
              errorText != null && 'bg-destructive300',
            )}
          />
          {errorText != null && (
            <Text className="my-3 text-center font-base text-base-m font-normal text-destructive300">
              {errorText}
            </Text>
          )}
        </View>
        <div className="mb-[20px] w-full">
          <div className="mx-6">
            <Button
              label="Next"
              type="primary"
              buttonType="submit"
              className="w-full"
              loading={status === 'sending-code' || status === 'submitting-code'}
              event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
              disabled={
                status === 'sending-code' ||
                status === 'submitting-code' ||
                !!codeSentDisabledSecondsRemaining ||
                isInCooldown
              }
              disabledClassName="opacity-30"
            />
          </div>
        </div>
      </form>
      <Text className="w-[90%] text-center font-base text-base-m font-normal text-base400">
        By signing up, you agree to the{' '}
        <a
          href={TOS_URL}
          target="_blank"
          className="text-yellow100 hover:cursor-pointer"
          onClick={e => isOpen && e.preventDefault()}
        >
          Terms
        </a>{' '}
        &{' '}
        <a
          href={PRIVACY_POLICY_URL}
          target="_blank"
          className="text-yellow100 hover:cursor-pointer"
          onClick={e => isOpen && e.preventDefault()}
        >
          Privacy Policy
        </a>{' '}
        and to receive automated messages. Reply STOP to unsubscribe. Msg & Data rates may apply.
      </Text>
    </DefaultLayout>
  );
};
