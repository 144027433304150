import { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { useIsClamped } from '../../hooks/useIsClamped';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { DSPRow } from './DSPRow';
import { CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export function MusicCampaignPreview({
  isPresave,
  artistName,
  artistImageUrl,
}: {
  isPresave: boolean;
  artistName: string;
  artistImageUrl: string | null | undefined;
}) {
  const { fields } = useCampaignForm();

  const [isTruncatedDescription, setIsTruncatedDescription] = useState(true);
  const toggleTruncated = useCallback(() => {
    setIsTruncatedDescription((value: boolean) => !value);
  }, []);
  const textRef = useRef<HTMLDivElement>(null);
  const { isClamped } = useIsClamped(textRef);

  return (
    <View className="flex flex-col items-center">
      <View className="relative mx-auto flex aspect-square w-full flex-col items-center justify-center overflow-hidden rounded-xl">
        <Image
          src={fields.image || fields.thirdPartyImageUrl || ''}
          alt="Blurred Album Cover"
          className="absolute inset-0 h-full w-full object-cover opacity-75 blur-xl filter"
        />

        <View className="absolute inset-0 bg-gradient-to-b from-transparent via-black/80 to-black" />

        <Image
          src={fields.image || fields.thirdPartyImageUrl || ''}
          alt="Album Cover"
          className="z-10 relative aspect-square w-2/3 rounded-xl object-cover"
        />
      </View>

      <View className="flex w-full -translate-y-7 flex-col items-center">
        <Text className="text-center text-title-xl font-semibold text-white">{fields.title}</Text>

        <View className="mt-2 flex items-center justify-center space-x-2">
          <ArtistProfileImage className="h-5 w-5" profileImageUrl={artistImageUrl} withVaultTheme />
          <Text className="text-base-m text-white/60">{artistName}</Text>
        </View>

        {isPresave ? (
          <>
            {fields.description && (
              <View
                className="mt-8 flex w-3/4 cursor-pointer flex-row items-center justify-center text-center text-base400"
                onClick={toggleTruncated}
              >
                <View
                  className={clsx(
                    '!text-base-m font-normal',
                    isTruncatedDescription ? 'line-clamp-2' : '',
                  )}
                  containerRef={textRef}
                >
                  {fields.description}
                </View>
                {isTruncatedDescription && isClamped && (
                  <span className="self-end !text-base-m font-normal text-base600">more</span>
                )}
              </View>
            )}
            <View className="mt-8 flex w-[90%] flex-col gap-8 rounded-xl bg-base800 px-6 py-8">
              {fields.dsps
                .filter(
                  dsp =>
                    dsp.showLink &&
                    dsp.uri &&
                    (dsp.key === ThirdPartyPlatform.Spotify ||
                      dsp.key === ThirdPartyPlatform.AppleMusic),
                )
                .map(dsp => (
                  <DSPRow
                    key={dsp.key}
                    type={CampaignType.Presave}
                    dspInfo={{ ...dsp, key: dsp.key as ThirdPartyPlatform }}
                  />
                ))}
            </View>
          </>
        ) : (
          <View className="mt-8 flex w-[90%] flex-col justify-center rounded-xl bg-base800 px-6 py-8">
            <Text className="text-center font-title text-title-l font-medium text-white">
              Are you listening?
            </Text>
            <Text className="mt-2 text-center !text-base-m text-white/60">
              Stream my new song and unlock exclusive rewards, directly from me.
            </Text>
            <Button className="mt-4" type="primary" label="Connect" />

            <View className="my-6 h-[1px] w-full bg-white/10" />

            <View className="flex flex-col gap-8">
              {fields.dsps
                .filter(dsp => dsp.showLink && dsp.uri)
                .map(dsp => (
                  <DSPRow
                    key={dsp.key}
                    type={CampaignType.Stream}
                    dspInfo={{ ...dsp, key: dsp.key as ThirdPartyPlatform }}
                  />
                ))}
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
