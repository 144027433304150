// Based on https://github.com/drac94/react-auth-code-input

import type { ChangeEvent } from 'react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'] as const;

export type AuthCodeProps = {
  allowedCharacters?: (typeof allowedCharactersValues)[number];
  ariaLabel?: string;
  autoFocus?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  inputClassName?: string;
  isPassword?: boolean;
  length?: number;
  placeholder?: string;
  onChange: (res: string) => void;
  hasError?: boolean;
};

type InputMode = 'text' | 'numeric';

type InputType = 'text' | 'tel' | 'password';

type InputProps = {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
};

export type AuthCodeRef = {
  focus: () => void;
  clear: () => void;
};

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },

  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
};

export const CodeInput = forwardRef<AuthCodeRef, AuthCodeProps>(
  (
    {
      allowedCharacters = 'alphanumeric',
      ariaLabel,
      autoFocus = true,
      containerClassName,
      disabled,
      inputClassName,
      hasError = false,
      isPassword = false,
      length = 6,
      placeholder,
      onChange,
    },
    ref,
  ) => {
    if (isNaN(length) || length < 1) {
      throw new Error('Length should be a number and greater than 0');
    }

    if (!allowedCharactersValues.some(value => value === allowedCharacters)) {
      throw new Error('Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric');
    }

    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const inputProps = propsMap[allowedCharacters];

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0]?.focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          inputsRef.current.forEach(input => {
            if (input) {
              input.value = '';
            }
          });
          inputsRef.current[0]?.focus();
        }
        sendResult();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0]?.focus();
      }
    }, [autoFocus]);

    const sendResult = () => {
      const res = inputsRef.current.map(input => input.value).join('');
      onChange && onChange(res);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = e;
      if (value.length > 1) {
        e.target.value = value.charAt(0);
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        if (!!inputProps?.pattern && value.match(inputProps.pattern)) {
          if (nextElementSibling !== null) {
            (nextElementSibling as HTMLInputElement).focus();
          }
        } else {
          e.target.value = '';
        }
      }
      sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      const target = e.target as HTMLInputElement;
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement;
            t.value = '';
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = '';
        }
        sendResult();
      }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('Text');

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput]?.value;
        if (inputProps?.pattern && pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            const currentInputElement = inputsRef.current[currentInput];
            if (currentInputElement) {
              currentInputElement.value = pastedCharacter;
              const nextSibling = currentInputElement.nextElementSibling;
              if (nextSibling instanceof HTMLInputElement) {
                nextSibling.focus();
                currentInput++;
              }
            }
          }
        }
      }
      sendResult();

      e.preventDefault();
    };

    const handleOnInput = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) return;
      const {
        target: { value },
      } = event;
      let currentInput = 0;

      if (event.target.value && event.target.value.length === length) {
        for (let i = 0; i < value.length; i++) {
          const pastedCharacter = value.charAt(i);
          if (inputProps?.pattern && pastedCharacter.match(inputProps.pattern)) {
            const currentInputElement = inputsRef.current[currentInput];
            if (currentInputElement) {
              currentInputElement.value = pastedCharacter;
              const nextSibling = currentInputElement.nextElementSibling;
              if (nextSibling instanceof HTMLInputElement) {
                nextSibling.focus();
                currentInput++;
              }
            }
          }
        }
        sendResult();
      }
    };

    const inputs = [];
    for (let i = 0; i < length; i++) {
      inputs.push(
        <input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          onInput={handleOnInput}
          {...inputProps}
          type={isPassword ? 'password' : inputProps?.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el;
          }}
          className={twMerge(
            'mr-3 h-[48px] w-[35px] rounded-sm border border-solid bg-white bg-clip-padding uppercase outline-none',
            'p-0 text-center font-base text-[24px] focus:appearance-none focus:outline-none',
            hasError
              ? 'border-destructive300 text-destructive300 placeholder:text-destructive300/50'
              : 'border-black/50 text-black placeholder:text-black/50 focus:border-black',
            inputClassName,
          )}
          autoComplete={i === 0 ? 'one-time-code' : 'off'}
          aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
          disabled={disabled}
          placeholder={placeholder}
        />,
      );
    }

    return <div className={containerClassName}>{inputs}</div>;
  },
);
