import { useEffect, useMemo } from 'react';
import { captureException } from '@sentry/react';
import msFn from 'ms';
import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';
import { gql, type StringDocumentNode } from '@soundxyz/gql-string';
import type { UseMutateAsyncFunction } from '@soundxyz/graphql-react-query/reactQuery';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { type ExecutionResultWithData, useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AppleMusicAuthConnectionDocument,
  AppleMusicDeveloperTokenDocument,
  AuthUserDocument,
  ConnectAppleMusicDocument,
  type ConnectAppleMusicMutation,
  type Exact,
  LinkAppleMusicDocument,
  type MutationConnectAppleMusicInput,
  UnlinkAppleMusicDocument,
} from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { IdempotentFunctionCall } from '../../utils/idempotent';
import { PersistenceStorage } from '../../utils/storeUtils';

gql(/* GraphQL */ `
  query AppleMusicDeveloperToken($origin: URL!, $accessSecret: String) {
    appleMusicDeveloperToken(origin: $origin, accessSecret: $accessSecret) {
      __typename
      ... on QueryAppleMusicDeveloperTokenSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation ConnectAppleMusic($input: MutationConnectAppleMusicInput!) {
    connectAppleMusic(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  query AppleMusicAuthConnection($userToken: String!) {
    appleMusicAuthConnection(userToken: $userToken) {
      userToken

      user {
        id
      }
    }
  }

  mutation LinkAppleMusic($input: MutationLinkAppleMusicInput!) {
    linkAppleMusic(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation UnlinkAppleMusic {
    unlinkAppleMusic {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const MusicKitInstance: {
  current: MusicKit.MusicKitInstance | null;
} = {
  current: null,
};

export const MusicKitReady = proxy({
  musickitLoaded: false,
  instanceLoaded: false,
});

export async function configureMusicKit({ developerToken }: { developerToken: string }) {
  if (!MusicKitReady.musickitLoaded) return;

  try {
    await MusicKit.configure({
      developerToken,
      app: {
        name: 'Vault.fm',
        build: '0.0.1',
      },
    });

    MusicKitInstance.current = MusicKit.getInstance();
    MusicKitReady.instanceLoaded = true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error configuring MusicKit', error);
    captureException(error, {
      extra: {
        developerToken,
      },
    });
  }
}

document.addEventListener('musickitloaded', () => {
  MusicKitReady.musickitLoaded = true;
});

export const AppleMusicConnectState = PersistenceStorage({
  schema: z.object({
    userToken: z.string().nullable(),
  }),
  key: 'appleMusicConnectState',
  eager: true,
});

const origin = new URL(window.location.href).origin;

const fifteenMinutes = msFn('15 minutes');

RefetchOnComplete({
  trigger: [ConnectAppleMusicDocument],
  refetch: [AppleMusicAuthConnectionDocument],
});

RefetchOnComplete({
  trigger: [LinkAppleMusicDocument, UnlinkAppleMusicDocument, ConnectAppleMusicDocument],
  refetch: [AppleMusicAuthConnectionDocument, AuthUserDocument],
});

export const connectAppleMusicApi = async ({
  userToken,
  connectAppleMusic,
  openBottomsheet,
  openToast,
  loggedInUserId,
}: {
  userToken: string;
  connectAppleMusic: UseMutateAsyncFunction<
    ExecutionResultWithData<ConnectAppleMusicMutation>,
    Error,
    Exact<{
      input: MutationConnectAppleMusicInput;
    }>,
    {
      query: StringDocumentNode<
        ConnectAppleMusicMutation,
        Exact<{
          input: MutationConnectAppleMusicInput;
        }>
      >;
    }
  >;
  openBottomsheet: ReturnType<typeof useBottomsheetContainer>['openBottomsheet'];
  openToast: ReturnType<typeof useToast>['openToast'];
  loggedInUserId: string | null;
}) => {
  try {
    const result = await connectAppleMusic({
      input: {
        userToken,
      },
    });

    if (result.data.connectAppleMusic.__typename !== 'MutationConnectAppleMusicSuccess') {
      if (result.data.connectAppleMusic.__typename === 'AppleMusicAlreadyLinkedError') {
        return openBottomsheet({
          type: 'CONFIRMATION',
          confirmationBottomsheetProps: {
            title: 'Apple Music already linked',
            subText:
              'Your Apple Music account is already linked to another Vault account. Would you like to link it to this account instead?',
            onConfirm() {
              connectAppleMusic({
                input: {
                  userToken,
                  overrideAccount: true,
                },
              })
                .then(result => {
                  if (
                    result.data.connectAppleMusic.__typename === 'MutationConnectAppleMusicSuccess'
                  ) {
                    openToast({
                      text: 'Apple Music account linked',
                      variant: 'success',
                    });
                  } else {
                    openToast({
                      text: result.data.connectAppleMusic.message,
                      variant: 'error',
                    });
                  }
                })
                .catch(error => {
                  captureException(error, { extra: { userToken, loggedInUserId } });
                  openToast({
                    text: 'An error occurred while linking Apple Music account. Please try again later',
                    variant: 'error',
                  });
                });
            },
          },
        });
      }

      return openToast({
        text: result.data.connectAppleMusic.message,
        variant: 'error',
      });
    }

    AppleMusicConnectState.set({
      userToken,
    });

    openToast({
      text: 'Apple Music account connected',
      variant: 'success',
    });
  } catch (error) {
    captureException(error, { extra: { userToken, loggedInUserId } });
    openToast({
      text: 'An error occurred while connecting Apple Music account. Please try again later',
      variant: 'error',
    });
  }
};

export function useAppleMusicAuth({ appleMusicEnabled }: { appleMusicEnabled: boolean }) {
  const { musickitLoaded, instanceLoaded } = useSnapshot(MusicKitReady);

  const { value: connectState } = AppleMusicConnectState.useStore();

  const { openToast } = useToast();

  const { openBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: unlinkAppleMusic, isLoading: isUnlinking } = useMutation(
    UnlinkAppleMusicDocument,
    {
      retry: 3,
      onSuccess(data) {
        if (data.data.unlinkAppleMusic.__typename !== 'MutationUnlinkAppleMusicSuccess') {
          openToast({
            text: data.data.unlinkAppleMusic.message,
            variant: 'error',
          });
        }
      },
    },
  );

  const { mutateAsync: linkAppleMusic, isLoading: isLinking } = useMutation(
    LinkAppleMusicDocument,
    {
      retry: 3,
    },
  );

  const { mutateAsync: connectAppleMusic, isLoading: isConnecting } = useMutation(
    ConnectAppleMusicDocument,
    {
      retry: 3,
    },
  );

  const isAnyMutationLoading = isUnlinking || isLinking || isConnecting;

  const { data: appleMusicConnection, isLoading: appleMusicConnectionLoading } = useQuery(
    AppleMusicAuthConnectionDocument,
    {
      variables: !!connectState?.userToken && { userToken: connectState.userToken },
      staleTime: 0,
      select(data) {
        return data.data.appleMusicAuthConnection;
      },
    },
  );

  useEffect(() => {
    if (appleMusicConnection === null) {
      AppleMusicConnectState.set({ userToken: null });
    }
  }, [appleMusicConnection]);

  const { loggedInUser, loginStatus } = useAuthContext();

  const loggedInUserId = loggedInUser?.id ?? null;

  const hasAppleMusicConnection = !!loggedInUser?.appleMusicAuthConnections.length;

  const { data: developerToken = null } = useQuery(AppleMusicDeveloperTokenDocument, {
    variables: {
      origin,
      accessSecret: import.meta.env.VITE_APPLE_MUSIC_SECRET_ACCESS_TOKEN,
    },
    staleTime: fifteenMinutes,
    refetchInterval: fifteenMinutes,
    enabled: appleMusicEnabled && !hasAppleMusicConnection && loginStatus !== LoginStatus.LOADING,
    select(data) {
      return data.data.appleMusicDeveloperToken.__typename ===
        'QueryAppleMusicDeveloperTokenSuccess'
        ? data.data.appleMusicDeveloperToken.data
        : null;
    },
  });

  useEffect(() => {
    if (!musickitLoaded) {
      if (typeof MusicKit !== 'undefined' && !MusicKitReady.musickitLoaded) {
        MusicKitReady.musickitLoaded = true;
      }
    }

    if (!musickitLoaded || !developerToken) return;

    IdempotentFunctionCall(() => configureMusicKit({ developerToken }), {
      key: `music-kit-${developerToken}`,
      clearOnFinish: false,
    });
  }, [musickitLoaded, developerToken]);

  return useMemo(() => {
    if (isAnyMutationLoading) {
      return {
        type: 'loading',
      } as const;
    }

    if (hasAppleMusicConnection) {
      return {
        type: 'apple-music-already-linked',
        unlink() {
          AppleMusicConnectState.set({ userToken: null });
          return Promise.all([unlinkAppleMusic({}), MusicKitInstance.current?.unauthorize()]).catch(
            error => {
              captureException(error, {
                extra: { userToken: appleMusicConnection?.userToken, loggedInUserId },
              });
              openToast({
                text: 'An error occurred while unlinking Apple Music account. Please try again later',
                variant: 'error',
              });
            },
          );
        },
      } as const;
    }

    if (appleMusicConnection) {
      if (appleMusicConnection.user && appleMusicConnection.user?.id === loggedInUserId) {
        return {
          type: 'apple-music-already-linked',
          unlink() {
            AppleMusicConnectState.set({ userToken: null });
            return Promise.all([
              unlinkAppleMusic({}),
              MusicKitInstance.current?.unauthorize(),
            ]).catch(error => {
              captureException(error, {
                extra: { userToken: appleMusicConnection.userToken, loggedInUserId },
              });
              openToast({
                text: 'An error occurred while unlinking Apple Music account. Please try again later',
                variant: 'error',
              });
            });
          },
        } as const;
      }

      if (!loggedInUserId) {
        return {
          type: 'apple-music-connected-without-user',
          userToken: appleMusicConnection.userToken,
        } as const;
      }

      return {
        type: 'link-apple-music',
        userToken: appleMusicConnection.userToken,
        async link() {
          try {
            const result = await linkAppleMusic({
              input: {
                userToken: appleMusicConnection.userToken,
                overrideAccount:
                  !!appleMusicConnection.user && appleMusicConnection.user.id !== loggedInUserId,
              },
            });

            if (result.data.linkAppleMusic.__typename !== 'MutationLinkAppleMusicSuccess') {
              if (result.data.linkAppleMusic.__typename === 'AppleMusicAlreadyLinkedError') {
                return openBottomsheet({
                  type: 'CONFIRMATION',
                  confirmationBottomsheetProps: {
                    title: 'Apple Music already linked',
                    subText:
                      'Your Apple Music account is already linked to another Vault account. Would you like to link it to this account instead?',
                    onConfirm() {
                      linkAppleMusic({
                        input: {
                          userToken: appleMusicConnection.userToken,
                          overrideAccount: true,
                        },
                      })
                        .then(result => {
                          if (
                            result.data.linkAppleMusic.__typename ===
                            'MutationLinkAppleMusicSuccess'
                          ) {
                            openToast({
                              text: 'Apple Music account linked',
                              variant: 'success',
                            });
                          } else {
                            openToast({
                              text: result.data.linkAppleMusic.message,
                              variant: 'error',
                            });
                          }
                        })
                        .catch(error => {
                          captureException(error, {
                            extra: { userToken: appleMusicConnection.userToken, loggedInUserId },
                          });
                          openToast({
                            text: 'An error occurred while linking Apple Music account. Please try again later',
                            variant: 'error',
                          });
                        });
                    },
                  },
                });
              }

              return openToast({
                text: result.data.linkAppleMusic.message,
                variant: 'error',
              });
            }

            openToast({
              text: 'Apple Music account linked',
              variant: 'success',
            });
          } catch (error) {
            captureException(error, {
              extra: { userToken: appleMusicConnection.userToken, loggedInUserId },
            });
            openToast({
              text: 'An error occurred while linking Apple Music account. Please try again later',
              variant: 'error',
            });
          }
        },
      } as const;
    }

    if (!instanceLoaded || !MusicKitInstance.current || appleMusicConnectionLoading) {
      return {
        type: 'loading',
      } as const;
    }

    const musicKitInstance = MusicKitInstance.current;

    if (musicKitInstance.isAuthorized && musicKitInstance.musicUserToken) {
      return {
        type: 'connected-without-api-confirmation',
        userToken: musicKitInstance.musicUserToken,
        disconnect() {
          return musicKitInstance.unauthorize();
        },
        connectWithApi() {
          return connectAppleMusicApi({
            userToken: musicKitInstance.musicUserToken,
            connectAppleMusic,
            openBottomsheet,
            openToast,
            loggedInUserId,
          });
        },
      } as const;
    }

    return {
      type: 'connect',
      async connect() {
        const result = await musicKitInstance.authorize();

        await connectAppleMusicApi({
          userToken: result,
          connectAppleMusic,
          openBottomsheet,
          openToast,
          loggedInUserId,
        });
      },
    } as const;
  }, [
    isAnyMutationLoading,
    hasAppleMusicConnection,
    appleMusicConnection,
    instanceLoaded,
    appleMusicConnectionLoading,
    unlinkAppleMusic,
    loggedInUserId,
    openToast,
    linkAppleMusic,
    openBottomsheet,
    connectAppleMusic,
  ]);
}
