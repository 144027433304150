import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import {
  faMobile,
  faPlus,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolderPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSave } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from '../../components/common/Text';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import {
  FOLDER_TITLE_MAX_LENGTH,
  FOLDER_TITLE_MIN_LENGTH,
  useVaultFolder,
} from '../../hooks/vault/useVaultFolder';

import { artistNavigationPath } from '../../utils/navigationUtils';
import { TextInputModal } from '../bottomsheets/TextInputModal';
import { getDSPName } from '../campaign/helpers';
import { CampaignType } from '../campaign/schema';
import { useCampaignForm } from '../campaign/useCampaignForm';
import { View } from '../common/View';
import { UploadButton } from '../layouts/ArtistLayout';

export const CreateButton = ({
  artistHandle,
  mainVaultId,
}: {
  artistHandle: string | undefined;
  mainVaultId: string;
}) => {
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const { setField } = useCampaignForm();
  const location = useLocation();
  const isInChat = location.pathname.includes('/chat');

  const { value: streamsEnabled } = useGate(FEATURE_GATES.STREAMS);
  const { value: presavesEnabled } = useGate(FEATURE_GATES.PRESAVES);

  const { createFolder } = useVaultFolder({
    vaultId: mainVaultId,
  });

  const onCreateFolderClick = useCallback(() => {
    if (artistHandle == null || mainVaultId == null) return;
    closeBottomsheet();
    openOverlay(
      <TextInputModal
        title="Create New Folder"
        confirmText="Create"
        placeholder="Untitled"
        minLength={FOLDER_TITLE_MIN_LENGTH}
        maxLength={FOLDER_TITLE_MAX_LENGTH}
        onConfirm={async text => {
          await createFolder({
            title: text,
            folderId,
            onSuccess: () => {
              if (isInChat) {
                navigate(artistNavigationPath(artistHandle, ''));
              }
              closeOverlay();
            },
          });
        }}
      />,
    );
  }, [
    artistHandle,
    closeBottomsheet,
    closeOverlay,
    createFolder,
    folderId,
    isInChat,
    mainVaultId,
    navigate,
    openOverlay,
  ]);

  const uploadButton =
    !!artistHandle && !!mainVaultId ? (
      <UploadButton
        folderId={folderId ?? null}
        customLabel="Upload media"
        artistLinkValue={artistHandle}
        vaultId={mainVaultId}
        className={twMerge(
          'flex w-full cursor-pointer items-center justify-start gap-4 self-stretch rounded-b-none rounded-t-lg border-none p-4 font-normal outline-none',
          'bg-vault_text/10 text-vault_text hover:bg-vault_text/20',
        )}
        onNavigate={() => {
          closeBottomsheet();
        }}
      />
    ) : null;

  const onPlusClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CUSTOM,
      shared: {
        withVaultTheme: true,
      },
      customBottomsheetProps: {
        body: (
          <View className="flex flex-col gap-0.5">
            {uploadButton}
            <CreateItem
              title="Create folder"
              icon={faFolderPlus}
              description="Organize your vault with folders"
              onClick={() => onCreateFolderClick()}
            />
            <CreateItem
              title="Text members"
              icon={faMobile}
              description="Message all members via SMS"
              onClick={() => {
                if (!artistHandle) return;
                navigate(artistNavigationPath(artistHandle, '/announcements/create'));
                closeBottomsheet();
              }}
            />
            {presavesEnabled && (
              <CreateItem
                title="Pre-save event"
                icon={faWaveformLines}
                description="Build hype for an upcoming release."
                onClick={() => {
                  if (!artistHandle) return;
                  setField('campaignType', CampaignType.Presave);
                  setField('dsps', [
                    {
                      key: ThirdPartyPlatform.Spotify,
                      name: getDSPName(ThirdPartyPlatform.Spotify),
                      uri: '',
                      buttonText: 'Presave',
                      showLink: true,
                    },
                    {
                      key: ThirdPartyPlatform.AppleMusic,
                      name: getDSPName(ThirdPartyPlatform.AppleMusic),
                      uri: '',
                      buttonText: 'Presave',
                      showLink: true,
                    },
                  ]);
                  navigate(artistNavigationPath(artistHandle, '/campaign/create'));
                  closeBottomsheet();
                }}
              />
            )}
            {streamsEnabled && (
              <CreateItem
                title="Streaming event"
                icon={faSave}
                description="Drive streams with a post-release page. "
                onClick={() => {
                  if (!artistHandle) return;
                  setField('campaignType', CampaignType.Stream);
                  setField('dsps', [
                    {
                      key: ThirdPartyPlatform.Spotify,
                      name: 'Spotify',
                      uri: '',
                      buttonText: 'Play',
                      showLink: true,
                    },
                    {
                      key: ThirdPartyPlatform.AppleMusic,
                      name: 'Apple Music',
                      uri: '',
                      buttonText: 'Play',
                      showLink: true,
                    },
                  ]);
                  navigate(artistNavigationPath(artistHandle, '/campaign/create'));
                  closeBottomsheet();
                }}
              />
            )}
          </View>
        ),
      },
    });
  });

  return (
    <button
      onClick={onPlusClick}
      className={twMerge(
        'flex h-12 max-h-12 min-h-12 w-12 cursor-pointer flex-row items-center justify-center gap-3 rounded-full border-none !font-base !text-[16px] !font-medium outline-none transition-all duration-500 ease-in-out hover:opacity-90 focus:outline-none md2:justify-start md2:p-3',
        'md2:w-auto md2:flex-row md2:gap-3',
        'bg-vault_accent text-vault_accent_text',
      )}
    >
      <FontAwesomeIcon className="text-[18px] md2:mx-2 md2:text-[20px]" icon={faPlus} />
      <Text className="hidden md2:block">Create</Text>
    </button>
  );
};

const CreateItem = ({
  title,
  icon,
  description,
  onClick,
}: {
  title: string;
  icon: IconDefinition;
  description: string;
  onClick: () => void;
}) => {
  return (
    <View
      className={twMerge(
        'flex w-full cursor-pointer flex-row items-center gap-4 self-stretch rounded-b-none border-none p-4 outline-none',
        'bg-vault_text/10 text-vault_text hover:bg-vault_text/20',
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon className="aspect-square" icon={icon} fontSize={24} />
      <View className="flex flex-col">
        <Text className="text-title-s font-medium text-vault_text">{title}</Text>
        <Text className="text-base-m font-normal text-vault_text/40">{description}</Text>
      </View>
    </View>
  );
};
