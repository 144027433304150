import { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  type DraggableAttributes,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { faLink } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronUp } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGripDots } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { CampaignHeaderSection } from '../../screens/vault/campaign/CreateCampaignPage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Toggle } from '../forms/Toggle';
import { getDSPIcon } from './helpers';
import { CampaignLinksType } from './schema';
import { CampaignType, type DSPInfo } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const LinksView = ({ type }: { type: CampaignLinksType }) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { fields, setField } = useCampaignForm();
  const [items, setItems] = useState<DSPInfo[]>(fields.dsps);

  useEffect(() => {
    setItems(fields.dsps);
  }, [fields.dsps]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.findIndex(item => item.key === active.id);
        const newIndex = items.findIndex(item => item.key === over?.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);
        setField('dsps', newOrder);
        return newOrder;
      });
    }
  }

  const headerData = () => {
    switch (type) {
      case CampaignLinksType.PresavePrereleaseLinks:
        return {
          title: 'Pre-release links',
          description: 'Setup the links that fans will pre-save your song from.',
        };
      case CampaignLinksType.PresaveReleaseLinks:
        return {
          title: 'Release links',
          description:
            'Your page will update after the song goes live. Add any extra links you want fans to access.',
        };
      case CampaignLinksType.StreamReleaseLinks:
        return {
          title: 'Release links',
          description: 'Add links to play your song and other important destinations to visit.',
        };
    }
  };

  const showAddLinkButton =
    type !== 'presavePreleaseLinks' ||
    !fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.Spotify) ||
    !fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.AppleMusic);
  const presaveLinks = items.filter(
    dsp => dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic,
  );
  const postReleaseLinks = items.filter(
    dsp => dsp.key !== ThirdPartyPlatform.Spotify && dsp.key !== ThirdPartyPlatform.AppleMusic,
  );

  return (
    <>
      <CampaignHeaderSection
        icon={faLink}
        subIcon={type === 'presaveReleaseLinks' ? faPlus : undefined}
        title={headerData().title}
        description={headerData().description}
      />
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <View className="flex h-full w-full flex-col gap-2">
          {fields.campaignType === CampaignType.Presave ? (
            <>
              {type === 'presavePreleaseLinks' ? (
                <SortableContext
                  items={presaveLinks.map(item => item.key)}
                  strategy={verticalListSortingStrategy}
                >
                  {presaveLinks.map(dsp => (
                    <SortableLinkInput key={dsp.key} type={type} dsp={dsp} />
                  ))}
                </SortableContext>
              ) : (
                postReleaseLinks.length > 0 && (
                  <SortableContext
                    items={postReleaseLinks.map(item => item.key)}
                    strategy={verticalListSortingStrategy}
                  >
                    {postReleaseLinks.map(dsp => (
                      <SortableLinkInput key={dsp.key} type={type} dsp={dsp} />
                    ))}
                  </SortableContext>
                )
              )}
            </>
          ) : (
            <SortableContext
              items={items.map(item => item.key)}
              strategy={verticalListSortingStrategy}
            >
              {items.map(dsp => (
                <SortableLinkInput key={dsp.key} type={type} dsp={dsp} />
              ))}
            </SortableContext>
          )}

          {showAddLinkButton && (
            <Button
              className="my-4 flex w-full items-center justify-center rounded-full bg-vault_text/15 py-4 text-base-m font-normal text-vault_text"
              leadingIcon={faPlus}
              type="secondary"
              label="Add link"
              onClick={() => {
                openBottomsheet({
                  type: 'DSP',
                  shared: {
                    withVaultTheme: true,
                    showFullScreen: true,
                    preventSwipeToDismiss: true,
                    hidePulleyBar: true,
                    hideCloseBottomsheetButton: true,
                  },
                  dspBottomsheetProps: {
                    type,
                  },
                });
              }}
            />
          )}
        </View>
      </DndContext>
    </>
  );
};

const SortableLinkInput = ({ type, dsp }: { type: CampaignLinksType; dsp: DSPInfo }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: dsp.key });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <LinkInput type={type} dsp={dsp} attributes={attributes} listeners={listeners ?? {}} />
    </div>
  );
};

const LinkInput = ({
  type,
  dsp,
  attributes,
  listeners,
}: {
  type: CampaignLinksType;
  dsp: DSPInfo;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap;
}) => {
  const { setField, fields, errors, validateField } = useCampaignForm();
  const [isExpanded, setIsExpanded] = useState(true);
  const [localDsp, setLocalDsp] = useState(dsp);

  const icon = getDSPIcon(dsp.key);

  const updateDSP = (key: keyof DSPInfo, value: string | boolean) => {
    setLocalDsp(prev => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const updatedDSPs = fields.dsps.map(d => (d.key === localDsp.key ? localDsp : d));
    setField('dsps', updatedDSPs);
    validateField('dsps');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDsp, setField, validateField]);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const hasError =
    errors.dsps?.some(e => e?.key === dsp.key && (e?.uri || e?.buttonText)) ||
    (errors.contentType &&
      (dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic));

  return (
    <View
      className={twMerge(
        'flex flex-col gap-2 rounded-lg bg-vault_text/5 p-4',
        !isExpanded && hasError && 'border border-solid border-destructive300',
      )}
    >
      <View
        className="flex cursor-default touch-none flex-row justify-between outline-none"
        onClick={handleToggleExpand}
        {...attributes}
        {...listeners}
      >
        <View className="flex flex-row items-center gap-2">
          <FontAwesomeIcon
            icon={faGripDots}
            fontSize="16"
            className="mr-1 cursor-move text-vault_text/60 outline-none"
            {...attributes}
            {...listeners}
          />
          <FontAwesomeIcon
            className={twMerge('h-5 w-5 text-vault_text', dsp.showLink ? '' : 'opacity-50')}
            icon={icon}
          />
          <Text
            className={twMerge(
              'font-title !text-title-s font-medium text-vault_text',
              dsp.showLink ? '' : 'opacity-50',
            )}
          >
            {dsp.name}
          </Text>
        </View>
        <View className="flex flex-row items-center gap-4 text-vault_text/50">
          {dsp.uri && dsp.uri.trim() !== '' && (
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              fontSize="14"
              className="cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                window.open(dsp.uri, '_blank');
              }}
            />
          )}
          <FontAwesomeIcon
            icon={isExpanded ? faChevronUp : faChevronDown}
            fontSize="16"
            className="cursor-pointer"
          />
        </View>
      </View>
      {isExpanded && (
        <View className="flex flex-col gap-5">
          <View className="flex flex-col">
            {dsp.key === ThirdPartyPlatform.Other && (
              <>
                <input
                  className={clsx(
                    'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
                    errors.dsps?.some(e => e?.key === dsp.key && e?.name)
                      ? 'border-destructive300'
                      : 'border-vault_text/10  focus:border-vault_text',
                  )}
                  placeholder="Link title"
                  value={localDsp.name}
                  onChange={e => updateDSP('name', e.target.value)}
                />
                {errors.dsps?.some(e => e?.key === dsp.key && e?.name) && (
                  <Text className="mt-2 text-base-s text-destructive300">
                    {errors.dsps.find(e => e?.key === dsp.key && e?.name)?.name}
                  </Text>
                )}
              </>
            )}
            <input
              className={clsx(
                'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
                errors.dsps?.some(e => e?.key === dsp.key && e?.uri) ||
                  (errors.contentType &&
                    (dsp.key === ThirdPartyPlatform.Spotify ||
                      dsp.key === ThirdPartyPlatform.AppleMusic))
                  ? 'border-destructive300'
                  : 'border-vault_text/10 focus:border-vault_text',
              )}
              placeholder={`Your ${localDsp.name} URI...`}
              value={localDsp.uri}
              onChange={e => updateDSP('uri', e.target.value)}
            />
            {(errors.dsps?.some(e => e?.key === dsp.key && e?.uri) ||
              (errors.contentType &&
                (dsp.key === ThirdPartyPlatform.Spotify ||
                  dsp.key === ThirdPartyPlatform.AppleMusic))) && (
              <Text className="mt-2 text-base-s text-destructive300">
                {errors.dsps?.find(e => e?.key === dsp.key && e?.uri)?.uri || errors.contentType}
              </Text>
            )}
            <input
              className="mt-2 rounded-md border border-solid border-vault_text/10 bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:border-vault_text focus:font-normal focus:outline-none"
              placeholder="Button title"
              value={localDsp.buttonText}
              onChange={e => updateDSP('buttonText', e.target.value)}
            />
          </View>

          <View className="mx-1 flex flex-row justify-between text-vault_text/60">
            <Toggle
              className="flex-row-reverse justify-end gap-2 text-vault_text"
              componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
              label="show link"
              checked={dsp.showLink}
              onChange={e => {
                updateDSP('showLink', e.target.checked);
              }}
            />

            {fields.campaignType === CampaignType.Stream ||
            type !== CampaignLinksType.PresavePrereleaseLinks ||
            (type === CampaignLinksType.PresavePrereleaseLinks &&
              fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.Spotify) &&
              fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)) ? (
              <FontAwesomeIcon
                icon={faTrash}
                fontSize="16"
                className="cursor-pointer"
                onClick={() => {
                  const updatedDsps = fields.dsps.filter(item => item.key !== dsp.key);
                  setField('dsps', updatedDsps);
                }}
              />
            ) : null}
          </View>
        </View>
      )}
    </View>
  );
};
