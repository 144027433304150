import React, { useEffect, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export function DesktopColorPickers({
  accentColor,
  backgroundColor,
  openPicker,
  setColor,
  setOpenPicker,
  setShowSuggestedAccents,
}: {
  accentColor: string | null;
  backgroundColor: string | null;
  openPicker: 'background' | 'accent' | null;
  setColor: (color: string) => void;
  setOpenPicker: React.Dispatch<React.SetStateAction<'background' | 'accent' | null>>;
  setShowSuggestedAccents: (value: boolean) => void;
}) {
  const pickerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setOpenPicker(null);
        setShowSuggestedAccents(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenPicker, setShowSuggestedAccents]);

  return (
    <div className="relative box-border flex w-full flex-col" ref={containerRef}>
      <Text className=" mb-4 font-title text-title-s font-medium">Colors</Text>
      <View className="flex flex-col rounded-[20px] bg-base800">
        <ColorPickerItem
          color={backgroundColor}
          label="Background color"
          isSelected={openPicker === 'background'}
          onClick={() => setOpenPicker(prev => (prev !== 'background' ? 'background' : null))}
        />
        <div className="h-[1px] w-full bg-black" />
        <ColorPickerItem
          color={accentColor}
          label="Accent color"
          isSelected={openPicker === 'accent'}
          subLabel="Links, buttons, badges"
          onClick={() => setOpenPicker(prev => (prev !== 'accent' ? 'accent' : null))}
        />
      </View>
      {openPicker &&
        createPortal(
          <div
            ref={pickerRef}
            className="fixed z-overlay"
            style={{
              left: containerRef.current
                ? containerRef.current.getBoundingClientRect().right + 10
                : 0,
              top: containerRef.current ? containerRef.current.getBoundingClientRect().top : 0,
            }}
          >
            <View className="w-[318px] rounded-[20px] bg-base800 shadow-[0px_0px_24px_0px_#000000]">
              <View className="flex px-5 pb-6 pt-5">
                <ColorPicker
                  color={openPicker === 'background' ? backgroundColor : accentColor}
                  setColor={setColor}
                  hexColorPickerRef={null}
                />
              </View>
            </View>
          </div>,
          document.body,
        )}
    </div>
  );
}

export function MobileColorPickers({
  accentColor,
  backgroundColor,
  setAccentColor,
  setBackgroundColor,
  setShowSuggestedAccents,
}: {
  accentColor: string | null;
  backgroundColor: string | null;
  setAccentColor: (color: string | null) => void;
  setBackgroundColor: (color: string | null) => void;
  setShowSuggestedAccents: (value: boolean) => void;
}) {
  const [openPicker, setOpenPicker] = useState<'background' | 'accent' | null>(null);
  const [tempColor, setTempColor] = useState<string | null>(null);

  const hexColorPickerRef = useRef<HTMLDivElement>(null);

  const handleOpenPicker = (type: 'background' | 'accent') => {
    setOpenPicker(type);
    setTempColor(type === 'background' ? backgroundColor : accentColor);

    setTimeout(() => hexColorPickerRef.current?.scrollIntoView({ behavior: 'smooth' }), 200);
  };

  const handleConfirm = () => {
    if (openPicker === 'background') {
      setBackgroundColor(tempColor);
    } else if (openPicker === 'accent') {
      setAccentColor(tempColor);
    }
    setOpenPicker(null);
    setShowSuggestedAccents(true);
  };

  const handleClear = () => {
    if (openPicker === 'background') {
      setBackgroundColor(null);
    } else if (openPicker === 'accent') {
      setAccentColor(null);
    }
    setOpenPicker(null);
    setShowSuggestedAccents(true);
  };

  return (
    <div className="box-border flex w-full flex-col">
      <Text className="mb-4 font-title text-title-s font-medium">Colors</Text>
      <View className="flex flex-col overflow-hidden rounded-[20px] bg-base800">
        <ColorPickerItem
          color={backgroundColor}
          label="Background color"
          isSelected={openPicker === 'background'}
          onClick={() => handleOpenPicker('background')}
        />
        <div className="h-[1px] w-full bg-black" />
        <ColorPickerItem
          color={accentColor}
          label="Accent color"
          subLabel="Links, buttons, badges"
          isSelected={openPicker === 'accent'}
          onClick={() => handleOpenPicker('accent')}
        />
      </View>

      {openPicker && (
        <>
          <div className="h-[1px] w-full bg-black" />
          <View className="mb-4 mt-6 flex flex-row justify-between">
            <Button
              leadingIcon={faXmark}
              iconOnly
              label="close color picker"
              className="text-[20px] text-white"
              onClick={handleClear}
            />
            <Button
              leadingIcon={faCheck}
              iconOnly
              label="close color picker"
              className="text-[20px] text-white"
              onClick={handleConfirm}
            />
          </View>

          <ColorPicker
            color={tempColor}
            setColor={setTempColor}
            hexColorPickerRef={hexColorPickerRef}
          />
        </>
      )}
    </div>
  );
}

function ColorPickerItem({
  color,
  label,
  isSelected,
  subLabel,
  onClick,
}: {
  color: string | null;
  label: string;
  isSelected: boolean;
  subLabel?: string;
  onClick: () => void;
}) {
  return (
    <View
      className="flex flex-row items-center gap-4 px-7 py-5 hover:cursor-pointer"
      onClick={onClick}
    >
      <div
        className={twMerge(
          'flex h-7 w-7 rounded-full',
          'border-solid',
          isSelected ? 'border-2 border-white' : 'border border-white/20',
          'bg-neutral500',
        )}
        style={
          !!color
            ? {
                backgroundColor: color,
                boxShadow: isSelected ? `inset 0 0 0 2px #1f1f1f` : undefined,
              }
            : undefined
        }
      />

      <View>
        <Text className="font-title text-title-s font-medium text-white">{label}</Text>
        {subLabel && <Text className="font-base text-base-m text-base500">{subLabel}</Text>}
      </View>
    </View>
  );
}

function ColorPicker({
  color,
  setColor,
  hexColorPickerRef,
}: {
  color: string | null;
  setColor: (color: string) => void;
  hexColorPickerRef: React.RefObject<HTMLDivElement> | null;
}) {
  return (
    <div className="flex w-full flex-col">
      <div ref={hexColorPickerRef} className="custom-picker flex">
        <HexColorPicker color={color ?? undefined} onChange={setColor} />
      </div>
      <div
        className={twMerge(
          'flex flex-row items-center gap-2 border-0 border-b-[1px] border-solid border-[#FFFFFF1A] py-5',
        )}
      >
        <div
          className={twMerge('flex h-4 w-4 rounded-full', ' border-2 border-solid border-white')}
          style={
            color ? { backgroundColor: color, boxShadow: `inset 0 0 0 2px #1f1f1f` } : undefined
          }
        />
        <HexColorInput
          color={color ?? undefined}
          onChange={setColor}
          prefixed
          className="w-full border-none bg-transparent text-title-s font-normal text-base50 focus:border-none focus:outline-none"
        />
      </div>
    </div>
  );
}
