import React, { useCallback, useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { useLocation } from 'react-router';
import { BaseBottomsheet } from '../components/bottomsheets/BaseBottomsheet';
import { useStableCallback } from '../hooks/useStableCallback';
import { useWindow } from '../hooks/useWindow';
import type { BottomsheetProps, ExtraBottomsheetProps } from '../types/bottomsheetTypes';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { createContainer } from '../utils/unstated';

const bottomsheetCloseSubscribers = new Set<() => void>();

export const BottomsheetContext = createContainer(function BottomsheetContext() {
  const [bottomsheetProps, setBottomsheetProps] = useState<BottomsheetProps | null>(null);
  const { pathname } = useLocation();
  const { isDesktop } = useWindow();
  const [isOpen, setIsOpen] = useState(false);

  const openBottomsheet = useCallback((_bottomSheetProps: BottomsheetProps) => {
    setBottomsheetProps(_bottomSheetProps);
    setIsOpen(true);
  }, []);

  const closeBottomsheet = useStableCallback(
    (type: 'swipe' | 'pointer' | 'esc' | 'other' | 'button' = 'other') => {
      bottomsheetProps != null &&
        trackEvent({
          type: EVENTS.CLOSE_BOTTOMSHEET,
          properties: { bottomsheetType: bottomsheetProps.type, type },
          pathname,
        });

      setIsOpen(false);

      setTimeout(() => setBottomsheetProps(null), isDesktop ? 0 : 200);

      for (const sub of bottomsheetCloseSubscribers) {
        try {
          sub();
        } catch (e) {
          captureException(e, {
            tags: {
              location: 'bottomsheetCloseSubscriber',
              closeType: type,
            },
          });
        }
      }
    },
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeBottomsheet('esc');
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [closeBottomsheet]);

  const onBottomsheetClose = useStableCallback((cb: () => void) => {
    bottomsheetCloseSubscribers.add(cb);
    return () => {
      bottomsheetCloseSubscribers.delete(cb);
    };
  });

  return {
    openBottomsheet,
    bottomsheetProps,
    closeBottomsheet,
    isOpen,
    onBottomsheetClose,
  };
});

const extraBottomSheetCloseSubscribers = new Set<() => void>();

export const ExtraBottomsheetContext = createContainer(() => {
  const [extraBottomsheetProps, setExtraBottomsheetProps] = useState<ExtraBottomsheetProps | null>(
    null,
  );

  const { pathname } = useLocation();
  const { isDesktop } = useWindow();
  const [isExtraBottomsheetOpen, setIsExtraBottomsheetOpen] = useState(false);

  const openExtraBottomsheet = useCallback((props: ExtraBottomsheetProps) => {
    setExtraBottomsheetProps(props);
    setIsExtraBottomsheetOpen(true);
  }, []);

  const closeExtraBottomsheet = useStableCallback(
    (type: 'swipe' | 'pointer' | 'esc' | 'other' | 'button' = 'other') => {
      extraBottomsheetProps != null &&
        trackEvent({
          type: EVENTS.CLOSE_BOTTOMSHEET,
          properties: { bottomsheetType: extraBottomsheetProps.type, type },
          pathname,
        });

      setIsExtraBottomsheetOpen(false);

      setTimeout(() => setExtraBottomsheetProps(null), isDesktop ? 0 : 200);

      for (const sub of extraBottomSheetCloseSubscribers) {
        try {
          sub();
        } catch (e) {
          captureException(e, {
            tags: {
              location: 'bottomsheetCloseSubscriber',
              closeType: type,
            },
          });
        }
      }
    },
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeExtraBottomsheet('esc');
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [closeExtraBottomsheet]);

  const onExtraBottomsheetClose = useStableCallback((cb: () => void) => {
    extraBottomSheetCloseSubscribers.add(cb);
    return () => {
      extraBottomSheetCloseSubscribers.delete(cb);
    };
  });

  return {
    openExtraBottomsheet,
    extraBottomsheetProps,
    closeExtraBottomsheet,
    isExtraBottomsheetOpen,
    onExtraBottomsheetClose,
  };
});

export const useBottomsheetContainer = () => BottomsheetContext.useContainer();
export const useExtraBottomsheetContainer = () => ExtraBottomsheetContext.useContainer();

export const BottomsheetContainer = () => {
  const { bottomsheetProps, closeBottomsheet, isOpen } = BottomsheetContext.useContainer();
  const { extraBottomsheetProps, closeExtraBottomsheet, isExtraBottomsheetOpen } =
    ExtraBottomsheetContext.useContainer();

  return (
    <>
      <BaseBottomsheet
        bottomsheetProps={bottomsheetProps}
        isOpen={isOpen}
        closeBottomsheet={closeBottomsheet}
        isExtraBottomsheet={false}
      />
      <BaseBottomsheet
        bottomsheetProps={extraBottomsheetProps}
        closeBottomsheet={closeExtraBottomsheet}
        isOpen={isExtraBottomsheetOpen}
        isExtraBottomsheet
      />
    </>
  );
};
