import msFn from 'ms';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { GetSpotifyAccessTokenDocument } from '../../graphql/generated';
import { useSpotifyAuth } from './useSpotifyAuth';

gql(/* GraphQL */ `
  query GetSpotifyAccessToken($authCode: String) {
    spotifyAuthAccessToken(authCode: $authCode) {
      __typename
      ... on QuerySpotifyAuthAccessTokenSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

const fiftyNineMinutes = msFn('59 minutes');

export function useSpotifyAccessToken() {
  const { value: spotifyConnect } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);

  const { authCode } = useSpotifyAuth({ presaveEnabled: spotifyConnect });
  const { loggedInUser } = useAuthContext();

  const { data, isLoading, isError, refetch } = useQuery(GetSpotifyAccessTokenDocument, {
    staleTime: fiftyNineMinutes,
    refetchInterval: fiftyNineMinutes,
    variables: { authCode },
    enabled: authCode != null || loggedInUser?.spotifyAuthConnection?.spotifyUserId != null,
    filterQueryKey: {
      authCode,
      userId: loggedInUser?.id,
    },
    select(data) {
      if (data.data.spotifyAuthAccessToken.__typename !== 'QuerySpotifyAuthAccessTokenSuccess') {
        return null;
      }
      return data.data.spotifyAuthAccessToken.data;
    },
  });

  return {
    accessToken: data,
    isLoading,
    isError,
    refetchToken: refetch,
  };
}
