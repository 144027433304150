import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPColor, getDSPIcon } from './helpers';
import { type CampaignType, type DSPInfo } from './schema';

export const DSPRow = ({ dspInfo, type }: { dspInfo: DSPInfo; type: CampaignType }) => {
  const icon = getDSPIcon(dspInfo.key);
  const color = getDSPColor(dspInfo.key);

  const onClick = () => {
    if (!dspInfo.uri) return;

    if (type == 'presave') {
      // eslint-disable-next-line no-console
      console.log('Presave with uri:', dspInfo.uri);
    } else {
      // eslint-disable-next-line no-console
      console.log('Play with uri:', dspInfo.uri);
    }
  };

  return (
    <View className="flex flex-row justify-between">
      <View className="flex flex-row items-center gap-3">
        <FontAwesomeIcon className="h-6 w-6" icon={icon} color={color} />
        <Text className="text-base-l font-semibold text-white">{dspInfo.name}</Text>
      </View>
      <Button
        className="min-w-[92px] rounded-full bg-white/20 px-4 py-2.5 text-base-m font-medium text-white"
        label={type == 'presave' ? dspInfo.buttonText || 'Pre-save' : dspInfo.buttonText || 'Play'}
        onClick={onClick}
      />
    </View>
  );
};
