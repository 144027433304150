import { type ReactNode, useCallback, useEffect } from 'react';
import {
  usePlaybackState,
  usePlayerDevice,
  useSpotifyPlayer,
  useWebPlaybackSDKReady,
  WebPlaybackSDK,
} from 'react-spotify-web-playback-sdk';
import { useSnapshot } from 'valtio';
import { PlayerType } from '../audio/AudioController';
import { SpotifyContext } from '../contexts/SpotifyContext';
import { useSpotifyAccessToken } from '../hooks/spotify/useSpotifyAccessToken';

export function SpotifyPlayerProvider({ children }: { children: ReactNode }) {
  const { accessToken } = useSpotifyAccessToken();

  const getOAuthToken = useCallback(
    (cb: (token: string) => void) => {
      if (accessToken == null) return;

      cb(accessToken);
    },
    [accessToken],
  );

  if (accessToken == null) {
    return <>{children}</>;
  }

  return (
    <WebPlaybackSDK initialDeviceName="Vault" getOAuthToken={getOAuthToken} connectOnInitialized>
      <InnerProvider>{children}</InnerProvider>
    </WebPlaybackSDK>
  );
}

function InnerProvider({ children }: { children: ReactNode }) {
  const isPlaybackReady = useWebPlaybackSDKReady();
  const device = usePlayerDevice();
  const player = useSpotifyPlayer();
  const playbackState = usePlaybackState(true);

  const audioPlayerType = useSnapshot(PlayerType).current;

  useEffect(() => {
    if (audioPlayerType !== 'spotify' && player != null && playbackState != null) {
      player?.pause();
    }
  }, [audioPlayerType, playbackState, player]);

  const value = {
    isPlaybackReady,
    device,
    player,
    playbackState,
  };

  return <SpotifyContext.Provider value={value}>{children}</SpotifyContext.Provider>;
}
