import { createContext, useContext } from 'react';

export type AppleMusicContextType = {
  duration: number;
  isPlaying: boolean;
  currentTrack: MusicKit.MediaItem | null;
  currentTime: number;
  musicKit: MusicKit.MusicKitInstance | null;
};

const defaultAppleMusicContext: AppleMusicContextType = {
  duration: 0,
  isPlaying: false,
  currentTrack: null,
  currentTime: 0,
  musicKit: null,
};

const AppleMusicContext = createContext<AppleMusicContextType>(defaultAppleMusicContext);

const useAppleMusicContext = () => useContext(AppleMusicContext);

export { AppleMusicContext, useAppleMusicContext };
