import { type LegacyRef, useMemo, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../../constants/routeConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import { useMutation } from '../../../../graphql/client';
import { UpsertUserContentViewDocument, VideoItemFragmentDoc } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useFreeTier } from '../../../../hooks/useFreeTier';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { useWindow } from '../../../../hooks/useWindow';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { constructQueryParams } from '../../../../utils/stringUtils';
import { MediaViewer } from '../../../message/MediaViewer';
import { VideoItem } from '../VideoItem';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';

export const UserVideo = ({
  video,
  containerRef,
  artistHandle,
}: {
  video: FragmentType<VideoItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const { md2 } = MediaQuery.useContainer();
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();

  const [menuHovered, setMenuHovered] = useState(false);

  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('code'); // referral link code
  const smsCampaignResponseShortcode = searchParams.get('c');
  const navigate = useNavigate();
  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    id: videoId,
    vault,
    title,
    uploadedMedia,
    vaultId,
    linkValue,
  } = getFragment(VideoItemFragmentDoc, video);
  const { loginStatus, loggedInUser } = useAuthContext();

  const link = useMemo(() => {
    const path = linkValue ? `/v/${linkValue}` : `/${videoId}`;
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [linkValue, videoId, artistHandle, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Track link copied to clipboard!',
  });

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });
  const { subscribeFreeTier } = useFreeTier();

  const hasSubscription = vault.activeSubscription != null;

  const { isDesktop } = useWindow();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const onPlayClick = () => {
    upsertUserContentView({ input: { vaultContentId: videoId } });

    if (isLocked || showUpsellInterstitial) {
      if (showUpsellInterstitial) {
        setTimeout(() => {
          openBottomsheet({
            type: 'GET_APP',
            getAppBottomsheetProps: {
              vaultId,
              interstitial: 'first_media',
              onContinue: null,
            },
          });

          trackEvent({
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: 'GET_APP',
              vaultId,
              interstitial: 'first_media',
            },
          });
        }, 2000);
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'JOIN_VAULT',
            joinType: hasSubscription ? 'paid' : 'free',
            artistHandle,
          },
        });

        openBottomsheet({
          type: BOTTOMSHEET_TYPES.JOIN_VAULT,
          shared: {
            withVaultTheme: true,
          },
          joinVaultBottomsheetProps: {
            artistHandle,
            vaultArtistProfileImage: vault.artist?.profileImage?.url ?? '',
            joinType: hasSubscription ? 'paid' : 'free',
            buttonTitle: hasSubscription ? 'Upgrade' : 'Join for free',
            title: hasSubscription ? 'Upgrade to view and more' : 'Join this vault to get started',
            onConfirm() {
              if (hasSubscription) {
                const queryParams = constructQueryParams({
                  code: inviteCode,
                  c: smsCampaignResponseShortcode,
                });
                navigate(artistNavigationPath(artistHandle, '/subscribe', queryParams));
                return;
              }

              // If logged in, subscribe to free tier
              if (!!loggedInUser?.id) {
                subscribeFreeTier({
                  input: { vaultId, inviteCode, smsCampaignResponseShortcode },
                });
                return;
              }

              // If not logged in, take to sign in page
              navigate(
                `${ROUTES.SIGN_IN}?${constructQueryParams({ artistHandle, inviteCode, c: smsCampaignResponseShortcode, openBottomSheet: 'freeTierModal' })}`,
              );
            },
          },
        });
      }
      return;
    }

    if (isDesktop) {
      openOverlay(
        <MediaViewer
          title={title ?? ''}
          medias={[{ id: uploadedMedia.id, type: uploadedMedia.mediaType, url: uploadedMedia.url }]}
          onClose={closeOverlay}
        />,
      );
    } else {
      videoRef.current?.play();
    }
  };

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onPlayClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={
        <VideoItem
          video={video}
          containerRef={containerRef}
          videoRef={videoRef}
          hasEllipsis={md2}
        />
      }
      disabled={false}
      disableHover={false}
    />
  );
};
