import { z } from 'zod';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { ScheduledEventAccessFeatureInput } from '../../graphql/generated';

export const ANNOUNCEMENT_MAX_CHARS = TEXT_MESSAGE_LIMIT;

export const ANNOUNCEMENT_ERRORS = {
  announcement: {
    max: `Announcement must be less than ${ANNOUNCEMENT_MAX_CHARS} characters`,
  },
};

export const AnnouncementFieldSchema = z.object({
  announcement: z.string().max(ANNOUNCEMENT_MAX_CHARS, {
    message: ANNOUNCEMENT_ERRORS.announcement.max,
  }),
  accessType: z.nativeEnum(ScheduledEventAccessFeatureInput),
  date: z.date(),
});

const AnnouncementErrorsSchema = z.object({
  announcement: z.string().nullish(),
  accessType: z.string().nullish(),
  date: z.string().nullish(),
});

export const AnnouncementSchema = z.object({
  fields: AnnouncementFieldSchema,
  errors: AnnouncementErrorsSchema,
});

export type AnnouncementState = z.infer<typeof AnnouncementSchema>;

export type AnnouncementStateFields = z.infer<typeof AnnouncementFieldSchema>;
export type AnnouncementErrors = z.infer<typeof AnnouncementErrorsSchema>;
