import React, { type FC, Fragment, useRef, useState } from 'react';
import { produce } from 'immer';
import { remove } from 'lodash-es';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { gql, type StringDocumentNode } from '@soundxyz/gql-string';
import type { OnData } from '@soundxyz/graphql-react-ws';
import { BackButton } from '../components/buttons/BackButton';
import { Button } from '../components/buttons/Button';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import {
  MessageBubble,
  MessageBubbleInteractions,
  SkeletonMessageBubble,
} from '../components/message/MessageBubble';
import { EmptyStateView } from '../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { invalidateOperations, useMutation, useQuery } from '../graphql/client';
import {
  type Exact,
  MessageBubbleFragmentDoc,
  type MessageChannelUpdatesSubscription,
} from '../graphql/generated';
import {
  ArtistByHandleDocument,
  FeatureTypename,
  type FragmentType,
  getFragment,
  PinnedMessageChannelDocument,
  TierTypename,
  UnpinAllMessagesDocument,
} from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useDrag } from '../hooks/useDrag';
import { useMessageChannelUpdatesSubscription } from '../hooks/useMessageChannelUpdatesSubscription';
import { useStableCallback } from '../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../hooks/useTierFeatures';
import { useSetActiveArtistChatHandle } from '../hooks/useVaultMessageChannel';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { compareDates, dateToTime, isSameDay } from '../utils/dateUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { pluralizeText } from '../utils/textUtils';

gql(/* GraphQL */ `
  query PinnedMessageChannel($input: QueryMessageChannelByArtistHandleInput!, $asArtistId: UUID) {
    messageChannelByArtistHandle(input: $input) {
      id
      vault {
        id
        artist: artistProfile {
          id
        }
        activeSubscription {
          id
          ...ActiveSubscriptionFeatures
        }
        tiers {
          __typename
          enabledFeatures {
            feature {
              __typename
            }
          }
        }
      }
      pinnedMessages {
        id
        createdAt
        ...messageBubble
        ...pinnedMessage
      }
      ...MessageChannelLayoutInfo
    }
  }

  mutation UnpinAllMessages($input: MutationRemoveAllPinnedMessageInput!) {
    removeAllPinnedMessage(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const defaultContainerSpacing = -65;

const defaultContainerMarginRight = `${defaultContainerSpacing}px`;

const PinnedMessagesScreen = () => {
  const { pathname } = useLocation();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  useSetActiveArtistChatHandle();

  const {
    data: pinnedMessagesData,
    isLoading: isLoadingPinnedMessages,
    isError: isErrorPinnedMessages,
    setQueryData,
  } = useQuery(PinnedMessageChannelDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      input: { artistHandle: artistHandle.toLowerCase() },
      asArtistId: loggedInUser?.artist?.id,
    },
  });

  const {
    data: artistData,
    isLoading: isLoadingArtist,
    isError: isErrorArtist,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
  });

  const isLoading = isLoadingPinnedMessages || isLoadingArtist;
  const isError = isErrorPinnedMessages || isErrorArtist;
  const unpinAllMessagesMutation = useMutation(UnpinAllMessagesDocument, {});

  if (artistHandle == null || isError) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const isOwner =
    loggedInUser?.artist?.id ===
    pinnedMessagesData?.data.messageChannelByArtistHandle?.vault?.artist?.id;

  const pinnedMessages = pinnedMessagesData?.data.messageChannelByArtistHandle?.pinnedMessages;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: pinnedMessagesData?.data.messageChannelByArtistHandle?.vault?.activeSubscription,
    isOwner:
      loggedInUser?.artist?.id ===
      pinnedMessagesData?.data.messageChannelByArtistHandle?.vault?.artist?.id,
  });
  const areSubscriptionTierBadgesVisible =
    pinnedMessagesData?.data.messageChannelByArtistHandle?.vault?.tiers
      ?.find(tier => tier.__typename === TierTypename.FreeTier)
      ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatWrite);
  const hasChatWriteAccess = activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true;

  const onMessageChannelUpdate: OnData<
    StringDocumentNode<
      MessageChannelUpdatesSubscription,
      Exact<{
        messageChannelId: string;
      }>
    >
  > = ({ data: { messageChannelUpdates } }) => {
    if (messageChannelUpdates.__typename !== 'SubscriptionMessageChannelUpdatesSuccess') {
      return;
    }

    if (messageChannelUpdates.data.__typename === 'PinMessageSubscription') {
      const { id, pinnedPriority } = messageChannelUpdates.data;
      setQueryData(previous => {
        return produce(previous, oldMessageChannelData => {
          const oldMessageChannel = oldMessageChannelData?.data.messageChannelByArtistHandle;
          if (oldMessageChannel == null) {
            return undefined;
          }
          const message = oldMessageChannel.pinnedMessages.find(message => message.id === id);
          remove(oldMessageChannel.pinnedMessages, message => message.id === id);
          if (pinnedPriority != null && message != null) {
            oldMessageChannel.pinnedMessages.splice(pinnedPriority, 0, message);
          }
        });
      });
    } else if (messageChannelUpdates.data.__typename === 'UnpinAllMessageSubscription') {
      setQueryData(previous => {
        return produce(previous, oldMessageChannelData => {
          const oldMessageChannel = oldMessageChannelData?.data.messageChannelByArtistHandle;
          if (oldMessageChannel == null) {
            return undefined;
          }
          oldMessageChannel.pinnedMessages = [];
        });
      });
    }
  };

  const onUnpinAllClick = () => {
    const messageChannelId = pinnedMessagesData?.data.messageChannelByArtistHandle?.id;
    if (messageChannelId != null) {
      openBottomsheet({
        type: 'CONFIRMATION',
        confirmationBottomsheetProps: {
          onConfirm: () => {
            trackEvent({
              type: EVENTS.UNPIN_ALL,
              properties: { channelId: messageChannelId },
              pathname,
            });

            unpinAllMessagesMutation.mutate({
              input: { messageChannelId },
            });
          },
          subText: 'Are you sure you want to unpin all messages',
        },
      });
    }
  };

  if (!isLoading && pinnedMessagesData?.data.messageChannelByArtistHandle == null) {
    return <Navigate to={`/${artistHandle}`} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop={false}
      hasChatReadAccess={false}
      messageChannelId={undefined}
      showBorder
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton />}
      headerCenter={
        pinnedMessages != null &&
        pinnedMessages.length > 0 && (
          <Text className="font-title text-title-m font-medium text-white">
            {pinnedMessages.length} pinned{' '}
            {pluralizeText({ count: pinnedMessages.length, text: 'message' })}
          </Text>
        )
      }
      footer={
        isOwner &&
        pinnedMessages != null &&
        pinnedMessages.length > 0 && (
          <Button
            label="Unpin all messages"
            className="px-4 py-7 font-title text-[16px]/[20px] font-medium text-yellow100"
            onClick={onUnpinAllClick}
            loading={unpinAllMessagesMutation.isLoading}
            event={{
              type: EVENTS.OPEN_BOTTOMSHEET,
              properties: {
                bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION,
                event: EVENTS.UNPIN_ALL,
              },
            }}
          />
        )
      }
      nonScrollingChildren={
        pinnedMessages != null &&
        pinnedMessages.length === 0 && (
          <EmptyStateView title="No pinned messages" subtitle="Pinned messages will appear here" />
        )
      }
    >
      <View className="w-full">
        {isLoading && (
          <>
            <SkeletonMessageBubble isAuthor />
            <SkeletonMessageBubble isAuthor={false} />
            <SkeletonMessageBubble isAuthor />
          </>
        )}
        {pinnedMessages != null &&
          pinnedMessagesData?.data.messageChannelByArtistHandle?.id != null &&
          !!artistHandle && (
            <PinnedMessagesView
              vaultId={pinnedMessagesData?.data.messageChannelByArtistHandle?.vault?.id}
              pinnedMessages={pinnedMessages}
              onMessageChannelUpdate={onMessageChannelUpdate}
              messageChannelId={pinnedMessagesData?.data.messageChannelByArtistHandle?.id}
              isOwner={isOwner}
              artistId={artistData?.data.artistLink?.artist.id}
              artistName={artistData?.data.artistLink?.artist.name}
              artistProfileImageUrl={artistData?.data.artistLink?.artist.profileImage?.url}
              artistLinkValue={artistHandle}
              areSubscriptionTierBadgesVisible={!!areSubscriptionTierBadgesVisible}
              hasChatWriteAccess={hasChatWriteAccess}
            />
          )}
      </View>
    </DefaultLayout>
  );
};

const PinnedMessagesView: FC<{
  pinnedMessages: (FragmentType<MessageBubbleFragmentDoc> & { id: string; createdAt: string })[];
  messageChannelId: string;
  onMessageChannelUpdate: OnData<
    StringDocumentNode<
      MessageChannelUpdatesSubscription,
      Exact<{
        messageChannelId: string;
      }>
    >
  >;
  isOwner: boolean;
  artistId: string | undefined;
  artistName: string | undefined;
  artistProfileImageUrl: string | undefined;
  artistLinkValue: string;
  areSubscriptionTierBadgesVisible: boolean;
  hasChatWriteAccess: boolean;
  vaultId: string | undefined;
}> = ({
  pinnedMessages,
  onMessageChannelUpdate,
  messageChannelId,
  isOwner,
  artistId,
  artistName,
  artistProfileImageUrl,
  artistLinkValue,
  areSubscriptionTierBadgesVisible,
  hasChatWriteAccess,
  vaultId,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [containerMarginRight, setContainerMarginRight] = useState(defaultContainerMarginRight);

  const resetMarginRight = useStableCallback(() => {
    setContainerMarginRight(defaultContainerMarginRight);
    MessageBubbleInteractions.enableLongPress = true;
  });

  const { resetDragging } = useDrag(ref, {
    onDragLeft({ amount }) {
      MessageBubbleInteractions.enableLongPress = false;
      setContainerMarginRight(`${Math.min(defaultContainerSpacing + amount, 0)}px`);
    },
    onPointerUp: resetMarginRight,
    onDragRight: resetMarginRight,
  });

  const navigate = useNavigate();

  useMessageChannelUpdatesSubscription({
    messageChannelId,
    onSubscriptionData(result) {
      switch (result.data.messageChannelUpdates.__typename) {
        case 'SubscriptionMessageChannelUpdatesSuccess': {
          switch (result.data.messageChannelUpdates.data.__typename) {
            case 'UnpinAllMessageSubscription': {
              navigate(artistNavigationPath(artistLinkValue, '/chat'));
              break;
            }
            case 'PinMessageSubscription': {
              invalidateOperations({
                operations: [PinnedMessageChannelDocument],
              });
              break;
            }
            case 'ReactionMessageSubscription': {
              const reactedMessageId = result.data.messageChannelUpdates.data.id;
              if (pinnedMessages.some(message => message.id === reactedMessageId)) {
                invalidateOperations({
                  operations: [PinnedMessageChannelDocument],
                });
              }
              break;
            }
          }

          break;
        }
        default: {
          break;
        }
      }
      onMessageChannelUpdate(result);
    },
  });

  const now = new Date();

  return (
    <View className="flex w-full flex-col gap-4 overflow-x-hidden px-[10px]" containerRef={ref}>
      {pinnedMessages.map((message, index) => {
        const { id, createdAt } = message;

        const nextMessage = pinnedMessages[index - 1];

        const nextMessageIsDifferentDay =
          !!nextMessage && !isSameDay(new Date(createdAt), new Date(nextMessage.createdAt));

        return (
          <Fragment key={id}>
            {nextMessageIsDifferentDay || !nextMessage ? (
              <p className="text-center text-base-s text-base400">
                <b>{compareDates(new Date(createdAt), now)}</b> {dateToTime(createdAt)}
              </p>
            ) : null}
            <MessageBubble
              vaultId={vaultId}
              message={message}
              key={id}
              isOwner={isOwner}
              containerMarginRight={containerMarginRight}
              onLongPress={() => {
                resetMarginRight();
                resetDragging();
              }}
              artistName={artistName}
              vaultArtistId={artistId}
              artistProfileImageUrl={artistProfileImageUrl}
              areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
              hasChatWriteAccess={hasChatWriteAccess}
              isVaultArtist={
                artistId === getFragment(MessageBubbleFragmentDoc, message).asArtist?.id
              }
            />
          </Fragment>
        );
      })}
    </View>
  );
};

export { PinnedMessagesScreen };
