import { proxy } from 'valtio';
import { ReleaseCampaignContentType, ThirdPartyPlatform } from '../../graphql/generated';
import type { CampaignState } from './schema';

export const initialCampaignState = () =>
  ({
    fields: {
      campaignType: null,
      mediaId: null,
      thirdPartyImageUrl: null,
      image: '',
      title: '',
      artist: '',
      description: '',
      releaseDate: new Date(),
      announcementDate: new Date(),
      dsps: [
        {
          key: ThirdPartyPlatform.Spotify,
          name: 'Spotify',
          uri: '',
          buttonText: '',
          showLink: true,
        },
        {
          key: ThirdPartyPlatform.AppleMusic,
          name: 'Apple Music',
          uri: '',
          buttonText: '',
          showLink: true,
        },
      ],
      contentType: ReleaseCampaignContentType.Album,
      shouldSendSms: true,
      message: '',
    },
    errors: {
      campaignType: null,
      image: null,
      title: null,
      artist: null,
      description: null,
      dsps: null,
      contentType: null,
      shouldSendSms: null,
      message: null,
    },
  }) satisfies CampaignState;

export const campaignState: CampaignState = proxy(initialCampaignState());

export type CampaignStateKeys = keyof CampaignState['fields'];
