import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGate } from 'statsig-react';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { MusicCampaignSkeleton, MusicCampaignView } from '../campaign/MusicCampaignView';

export const PresavePage = () => {
  const { campaignSlug } = useParams<{
    campaignSlug: string;
  }>();

  const { value: presaveEnabled, isLoading: isLoadingGate } = useGate(FEATURE_GATES.PRESAVES);
  const navigate = useNavigate();

  const { loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  // TODO switch to fetch for campaign with slug
  const { data, isLoading } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  useEffect(() => {
    if (isLoadingGate) return;
    if (!presaveEnabled || !artistHandle) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [artistHandle, isLoadingGate, navigate, presaveEnabled]);

  if (isLoading || !data) {
    return <MusicCampaignSkeleton />;
  }

  return (
    <MusicCampaignView
      isPreview={false}
      artistName={data?.data?.artistLink?.artist?.name || artistHandle || ''}
      artistImageUrl={data?.data?.artistLink?.artist?.profileImage?.url}
      campaignSlug={campaignSlug}
    />
  );
};
