import { z } from 'zod';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { ReleaseCampaignContentType } from '../../graphql/generated';

export const CAMPAIGN_MESSAGE_MAX_CHARS = TEXT_MESSAGE_LIMIT;

export type DSPInfo = {
  key: ThirdPartyPlatform;
  name: string;
  uri: string;
  buttonText: string;
  showLink: boolean;
};

export enum CampaignType {
  Presave = 'presave',
  Stream = 'stream',
}

export enum CampaignSteps {
  Scan = 'scan',
  Setup = 'setup',
  PresavePrereleaseLinks = 'presavePreleaseLinks',
  PresaveReleaseLinks = 'presaveReleaseLinks',
  StreamReleaseLinks = 'streamReleaseLinks',
  Release = 'release',
  Preview = 'preview',
}

export enum CampaignLinksType {
  PresavePrereleaseLinks = 'presavePreleaseLinks',
  PresaveReleaseLinks = 'presaveReleaseLinks',
  StreamReleaseLinks = 'streamReleaseLinks',
}

export const CAMPAIGN_ERRORS = {
  image: {
    required: 'You must upload an image.',
  },
  title: {
    required: 'Title must not be empty.',
  },
  artist: {
    required: 'Artist name must not be empty.',
  },
  dsps: {
    invalid: (dsp: string) => `${dsp} URI must be a valid URL.`,
  },
  contentType: {
    mismatch: 'Links must all be either an album or track.',
  },
  message: {
    max: `Message must be less than ${CAMPAIGN_MESSAGE_MAX_CHARS} characters.`,
  },
};

// Define the validation schema for the fields
export const CampaignFieldSchema = z.object({
  campaignType: z.enum([CampaignType.Presave, CampaignType.Stream]).nullable(),
  mediaId: z.string().nullable(),
  thirdPartyImageUrl: z.string().nullable(),
  image: z.string().min(1, { message: CAMPAIGN_ERRORS.image.required }),
  title: z.string().min(1, { message: CAMPAIGN_ERRORS.title.required }),
  artist: z.string().min(1, { message: CAMPAIGN_ERRORS.artist.required }),
  description: z.string().optional(),
  releaseDate: z.date(),
  announcementDate: z.date(),
  dsps: z.array(
    z.object({
      key: z.nativeEnum(ThirdPartyPlatform),
      name: z.string(),
      uri: z.string().url().or(z.literal('')),
      buttonText: z.string(),
      showLink: z.boolean(),
    }) as z.ZodType<DSPInfo>,
  ),
  contentType: z.nativeEnum(ReleaseCampaignContentType),
  shouldSendSms: z.boolean(),
  message: z.string().max(CAMPAIGN_MESSAGE_MAX_CHARS, {
    message: CAMPAIGN_ERRORS.message.max,
  }),
});

// Define the schema for the errors
const CampaignErrorsSchema = z.object({
  campaignType: z.string().nullish(),
  image: z.string().nullish(),
  title: z.string().nullish(),
  artist: z.string().nullish(),
  description: z.string().nullish(),
  releaseDate: z.string().nullish(),
  dsps: z
    .array(
      z.object({
        key: z.nativeEnum(ThirdPartyPlatform).nullish(),
        name: z.string().nullish(),
        uri: z.string().nullish(),
        buttonText: z.string().nullish(),
        showLink: z.boolean().nullish(),
      }),
    )
    .nullish(),
  contentType: z.string().nullish(),
  shouldSendSms: z.string().nullish(),
  message: z.string().nullish(),
});

// Combine the fields and errors schemas into one
export const CampaignSchema = z.object({
  fields: CampaignFieldSchema,
  errors: CampaignErrorsSchema,
});

// Infer the TypeScript types from the schema
export type CampaignState = z.infer<typeof CampaignSchema>;
export type CampaignStateFields = z.infer<typeof CampaignFieldSchema>;
export type CampaignErrors = z.infer<typeof CampaignErrorsSchema>;
