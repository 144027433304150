import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faReceipt } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ReceiptItem, ReceiptItemSkeleton } from './ReceiptItem';
import { Item } from './shared';

// TODO: Integrate when BE is ready
export const ReceiptsSection = () => {
  const hasReceipts = false;
  const isInitialLoading = false;

  if (isInitialLoading) {
    return (
      <Item header="New events">
        {Array.from({ length: 3 }).map((_, index) => (
          <ReceiptItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  return (
    <Item
      header="Receipts"
      // TODO: Add link when page is ready
      // headerLink={
      //   <Link
      //     to="/"
      //     className="text-base text-[14px]/[16px] text-vault_text/50 no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
      //   >
      //     See all
      //   </Link>
      // }
    >
      {hasReceipts ? (
        <ReceiptItem title="Receipt Title" createdAt="date &middot; time" />
      ) : (
        <View className="flex flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
          <View className="flex flex-col gap-4">
            <FontAwesomeIcon
              icon={faReceipt}
              size="2xl"
              className="flex items-center text-vault_text/90"
            />
            <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
              Collect receipts by pre-saving, streaming, and more.
            </Text>
          </View>

          <View className="h-[1px] w-full bg-vault_text/5" />

          <View className="flex flex-col gap-4">
            <FontAwesomeIcon
              icon={faGift}
              size="2xl"
              className="flex items-center text-vault_text/90"
            />
            <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
              The more receipts you collect, the more rewards & access you get.
            </Text>
          </View>
        </View>
      )}
    </Item>
  );
};
